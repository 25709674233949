.placement {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.for-division {
    background-color: white;
    width: 80%;
    place-self: center;
    border-radius: 12px;
    padding: 0 40px;
    display: flex;
}
.for-division .content {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.for-division .sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
    gap: 0;
}
.for-division .sub-content {
    margin-top: 3vh;
}
.for-division .officer-mail {
    display: flex;
}
.for-division .user-testimonials {
    margin-top: 2.5rem;
}
.for-division .carousel-img {
    height: 10%;
    object-fit: contain;
    width: 100%;
}
.for-division .carousel-img img {
    object-fit: contain;
    height: 100px;
}
.for-division .carousel-item {
    padding: 1rem;
    height: 300px;
    margin: 20px;
    width: 100%;
    border-radius: 15px;
    display: grid;
    place-items: center;
}
.for-division .carousel-right::-webkit-scrollbar {
    display: none;
}
.for-division .carousel-right {
    height: 100px;
    overflow: scroll;
}
.for-division .heading {
    place-self: flex-end;
    height: fit-content;
    color: var(--heading-color);
    display: flex;
    justify-content: center;
    width: 100%;
}

.for-division .heading h1 {
    width: fit-content;
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-size: 2.6rem;
}
.for-division .sub-heading {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.for-division .sub-heading::after {
    margin-top: 5px;
    content: "";
    height: 5px;
    width: 60px;
    background-color: #2c3e50;
}

.for-division .tnp-officer {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px 0px;
    border-radius: 3px;
    margin-bottom: 1rem;
}
.for-division .officer-info {
    width: 90%;
    margin-left: 5%;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}
.for-division .officer-mail {
    text-decoration: none;
    width: 90%;
    margin: 10px 0;
    margin-left: 5%;
}

.officer-mail a {
    text-decoration: none;
    color: var(--accent-color);
}

.head-strip {
    display: flex;
    align-items: center;

    height: 5vh;
    width: 20vw;
    border: 1px solid var(--accent-color);
    background-color: white;
    padding: 5px;
    border-radius: 3px;
}
.head-strip p {
    margin-left: 2vw;
}
.strip {
    height: 5vh;
    width: 20vw;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: var(--color-primary);
    border-radius: 3px;
    padding: 10px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    transition: all 0.4s;
}
.strip:hover {
    cursor: pointer;
    transform: translateY(-3px);
    background-color: var(--accent-color);
}

.strip:hover a,
.strip:hover i {
    color: white;
}

.sidebar a,
.strip i {
    transition: all 0.4s;
    color: white;
    text-decoration: none;
}
@media screen and (max-width: 1400px) {
    .for-division {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .for-division {
        display: block;
        width: 100%;
        padding: 20px;
    }
    .head-strip {
        width: 80vw;
        height: 6vh;
    }
    .strip {
        width: 80vw;
        height: 6vh;
    }
    .for-division .content {
        width: 100%;
    }
    .for-division .sidebar {
        width: 100%;
        margin-top: 5vh;
    }
}
