@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&family=Poppins:wght@400;500;700;800;900&family=Zilla+Slab:wght@400;500;600;700&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-padding-top: 60px;
    font-family: "Poppins",Verdana, Geneva, Tahoma, sans-serif;
    /* font-family: 'Poppins', sans-serif, 'Segoe UI', Tahoma, Geneva, Verdana; */
}
body {
    background-color: var(--bg-primary);
    /* max-width: 1800px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto; */
}
/* {
    --accent-color: #0d1128;
    --color-primary: #313552;
    --color-secondary: #B8405E;
} */
:root {
    --accent-color: #0d1128;
    --color-primary: #313552;
    --color-secondary: #e6a630;
    --color-secondary-light:#eeb741;
    --bg-primary: #fff;
    --heading-color: #646464;
    --navbar-height: 0px;
    --navbar-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    --y-pad: 0 30px;
    --box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.2);
}

.row {
    display: flex;
}

.column {
    display: flex;
    flex-direction: column;
}

.r-c-x {
    justify-content: center;
}

.r-c-y {
    align-items: center;
}

.c-c-x {
    justify-content: center;
}

.c-c-y {
    align-items: center;
}

.show {
    display: block;
}

.hide {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Zilla Slab", Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: #2d2e2e;
    line-height: 1.27273;
}

.title-bold {
    font-weight: 600;
}

h2,
.h2 {
    font-size: 44px;
}

h3,
.h3 {
    font-size: 38px;
}

h4,
.h4 {
    font-size: 32px;
}

h5,
.h5 {
    font-size: 26px;
}

h6,
.h6 {
    font-size: 18px;
}

@media screen and (max-width: 567px) {
    h1,
    .h1 {
        font-size: 32px;
    }

    h2,
    .h2 {
        font-size: 28px;
    }

    h3,
    .h3 {
        font-size: 24px;
    }

    h4,
    .h4 {
        font-size: 20px;
    }

    h5,
    .h5 {
        font-size: 18px;
    }
    h6,
    .h6 {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    h1,
    .h1 {
        font-size: 40px;
    }

    h2,
    .h2 {
        font-size: 36px;
    }

    h3,
    .h3 {
        font-size: 32px;
    }

    h4,
    .h4 {
        font-size: 28px;
    }

    h5,
    .h5 {
        font-size: 24px;
    }
    h6,
    .h6 {
        font-size: 20px;
    }
}
p{
    text-align:justify;
}

.layout-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    grid-gap: 6rem;
    gap: 6rem;
}

.layout-header {
    display: flex;
    height: 250px;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
}

.layout-header .layout-title {
    text-align: center;
    word-spacing: 0.5rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    color: white;
}

.breadcrumb-list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: var(--color-primary);
    grid-gap: 1rem;
    gap: 1rem;
    list-style: none;
}

.breadcrumb-item {
    display: flex;
    height: 30px;
    grid-gap: 1rem;
    gap: 1rem;
}
.breadcrumb-item i {
    /* color: var(--color-secondary); */
    color: white;
}

.breadcrumb-item a,
.breadcrumb-item span {
    color: var(--color-secondary);
    text-decoration: none;
}

.layout-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .layout-header {
        height: 150px;
        align-items: flex-start;
    }
    .layout-header .layout-title {
        text-align: center;
    }
}

.info-page {
    background: var(--bg-primary);
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 50vh;
    padding: 0 40px;
}

.info-page .heading {
    color: #646464;
}

.info-page .title {
    font-size: 2.6rem;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--accent-color);
}

.info-page .entry-list {
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 24px;
    gap: 24px;
}

.entry-list li {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #646464;
    font-size: 1.2rem;
}

.entry-list li a:hover {
    color: var(--accent-color);
}

.entry-list .info_page_card {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;
    background: var(--bg-primary);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.header {
    position: relative;
    
    
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    padding-top: 1rem;
    /* border-bottom: 10px solid var(--color-secondary); */
}

.header .head {
    display: flex;
}

.head__link {
    font-weight: 900;
    font-size: 1.5rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #0d0d0d;
}

.head__logo {
    flex: 3 1;
}

.head__img {
    height: 100px;
}

.head__contact {
    display: flex;
    justify-content: flex-end;
    flex: 1 1;
}

.head__contact .contact__item {
    grid-gap: 1rem;
    gap: 1rem;
    height: 100%;
    display: flex;
    width: 200px;
    align-items: center;
}

.contact-icon {
    font-size: 1.5rem;
    color: var(--color-secondary);
}

.contact__value {
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
}

.navbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    max-width:100%;
    margin: 0 auto;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 2rem;

    background-color: var(--color-primary);
    height: 70px;

}

.navbar__list {
    
    height: 100%;
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
    width: 100%;
}

.navbar__item {
    display: flex;
    height: 100%;
    position: relative;
    top: 0;
}

.navbar__item:hover .navbar__dropdown {
    display: block;
    visibility: visible;
    transform: translateY(0);
    z-index: 1;
    opacity: 1;
    background-color: var(--color-secondary);
}

.navbar__item .navbar__link {
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    text-decoration: none;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.navbar__link i {
    font-size: 1rem;
}

.navbar__link:hover {
    color: var(--color-secondary);
}

.navbar__dropdown {
    position: absolute;
    top: 70px;
    left: -20px;
    visibility: hidden;
    min-width: 300px;
    background-color: var(--color-primary);
    transition: all 0.1s linear;
    transform: translateY(-10%);
    z-index: -1;
    opacity: 0;
}

.navbar__item:nth-last-of-type(2) .navbar__dropdown {
    left: auto;
    right: -20px;
}

.navbar__dropdown-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    padding: 1rem 2rem;
    width: 100%;
}

.navbar__dropdown-item a {
    text-decoration: none;
    color: #fff;
    transition: all 0.1s ease-in-out;
}

.navbar__dropdown-item:hover a {
    color: var(--color-primary);
}

.navbar-tab {
    display: none;
}

.contact__value:hover {
    text-decoration: underline;
    color: var(--color-secondary);
}

@media screen and (max-width: 768px) {
    .header {
        max-width: 100%;
    }
    .head {
        max-width: 90%;
        margin: 0 auto;
        flex-direction: column;
        margin-bottom: 1rem;
    }
    .head__emblem {
        display: none;
    }
    .navbar {
        display: none;
    }
    .navbar-tab {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--color-primary);
        align-items: flex-start;
        padding: 1rem 2rem;
        grid-gap: 2rem;
        gap: 2rem;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    .navbar-toggler {
        display: flex;
        grid-gap: 1rem;
        gap: 1rem;
        align-items: center;
        outline: none;
        border: none;
        background: transparent;
        color: rgba(255, 255, 255, 0.9);
        font-size: 1.2rem;
    }

    .navbar-tab__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        grid-gap: 1rem;
        gap: 1rem;
    }

    .navbar-tab__item {
        list-style-type: none;
    }

    .navbar-tab__item a,
    .navbar-tab__item div {
        text-decoration: none;
        display: flex;
        grid-gap: 10px;
        gap: 10px;
        align-items: center;
        color: #fff;
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        transition: max-height 1s linear, opacity 0.2s, visibility 0s;
        max-height: 900px;
        opacity: 1;
        visibility: visible;
    }
    .navbar-collapse:not(.expand) {
        transition: all 1s linear;
        max-height: 0;
        display: block;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
    }

    .navbar-tab__dropdown-collapse {
        transition: all 1s linear;
        max-height: 500px;
        opacity: 1;
        visibility: visible;
    }

    .navbar-tab__dropdown-collapse:not(.expand) {
        transition: all 1s linear;
        max-height: 0;
        display: block;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
    }
    .navbar-tab__dropdown-item {
        margin: 10px 0;
        margin-left: 2rem;
    }
}

header .name_title {
    font-size: 20px;
}

header .sub_tagline
{
    color: #797979;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    /* width: 400px; */
    /* background-color: aqua; */
}

@media screen and (max-width: 576px) {
    .head__logo a {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .head__contact {
        display: none;
        grid-gap: 1rem;
        gap: 1rem;
        flex-direction: column;
    }
}

.home-page {
   
}
.home-page .main-content {
    background-color: var(--bg-primary);
    margin-top: calc(var(--navbar-height));
}

.clg-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: calc(100vh - var(--navbar-height));
    background: url(/static/media/clg.06bf7591.jpg);
    background-size: cover;
}

.info {
    margin-top: var(--navbar-height);
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.all-content {
    /* padding: 20px; */
    background-color: var(--bg-primary);
}

@media (max-width: 576px) {
    .clg-bg {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        height: calc(100vh);
        background: url(/static/media/clg.06bf7591.jpg);
        background-size: cover;
        background-repeat: repeat;
    }
}


.loader {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}


.content-loader{
    width: 90%;
    height: 400px;
    /* border: solid 2px #ccc; */

}
.student-section {
    background: var(--bg-primary);
    padding-top: var(--navbar-height);
    display: flex;
    height: 100vh;
    flex-direction: column;
}
.heading {
    text-align: center;
}
.list {
    display: grid;
    place-items: center;
}
.item:hover {
    cursor: pointer;
    color: var(--accent-color);
}

@media (max-width: 810px) {
    .student-section {
        padding-top: 0;
    }
}

.footer-section {
    justify-content: space-between;
    margin-top: 3rem;
    width: 100%;
    background-color: #232323;
    color: #fff;
}

.footer-content {
    margin: 1rem;
    height: 100%;
}

.footer-logo {
    margin: 0 10px;
    width: 25%;
    height: 350px;
}

.footer-logo img {
    width: 8rem;
    border-radius: 50%;
}

.footer-logo .logo-text {
    padding: 10px;
    text-align: center;
}
.college_name{
    text-align: center;
}
.terms-condition {
    justify-content: space-between;
    padding: 0 20px;
    color: white;
    height: 40px;
    background-color: var(--accent-color);
}

.sections {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    padding: 20px 20px;
}

.link-section {
    width: 250px;
}

.link-section ul {
    list-style: none;
    grid-gap: 10px;
    gap: 10px;
}

.link-title {
    color: white;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 10px;
}

.footer-navItem a {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
    text-decoration: none;
}

.footer-navItem a:hover {
    color: var(--color-secondary);
}

/*.social-media .social-icon {
    margin: 10px;
    height: 30px;
    width: 30px;
}

.social-media .social-icon a {
    color: var(--color-secondary);
    font-size: 1.1rem;
}
/* .social-media-icons .social-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #2e2e2e;
} here

.social-icon a i {
    font-size: 2rem;
    color: #c6c6c6;
}

.social-icon:hover a i {
    color: var(--color-secondary);
}

/*
 *
 * */
	.social-media .social-icon {
    margin: 10px;
    height: 40px; /* Increase the height */
    width: 40px; /* Increase the width */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #2e2e2e; /* Dark background */
    transition: background-color 0.3s ease; /* Smooth background color transition */
}

.social-media .social-icon a {
    color: #c6c6c6; /* Initial icon color (gray) */
    font-size: 1.6rem; /* Increase font size */
    transition: color 0.3s ease; /* Smooth color transition */
}

.social-media .social-icon:hover {
    background-color: var(--color-secondary); /* Change background color on hover */
}

.social-media .social-icon:hover a {
    color: #fff; /* Change icon color on hover */
}

@media (max-width: 576px) {
    .footer-logo {
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 20px 0;
        margin: 0px;
        border: none;
        border-bottom: solid 1px var(--accent-color);
    }

    .footer-content {
        flex-direction: column;
    }

    .sections {
        align-items: center;
        flex-direction: column;
        border: none;
    }

    .link-section {
        width: 100%;
    }
    .terms-condition {
        justify-content: space-between;
        padding: 10px;
        color: white;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        background-color: var(--accent-color);
    }
}

@media (max-width: 768px) {
}

.visit-counter {
    color: white;
}

.page_404 {
    margin-top: var(--navbar-height);
    padding: 40px 0;
    height: 100vh;
    background: #fff;
}

.page_404 .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    text-align: center;
    width: 80%;
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    background-size: contain;
    background-repeat: no-repeat;
    height: 400px;
    background-position: center;
}

.four_zero_four_bg h1 {
    font-size: 80px;
    color: #646464;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
 
    padding: 10px 20px;
    margin: 20px 0;
    display: inline-block;
}
.contant_box_404 {
    text-align: center;
    color: #555;
}

.wysiwyg #content-data {
    padding: 0 6rem;
    display: flex;
    flex-direction: column;
    text-align:justify;
}
#content-data span,
#content-data div,
#content-data p {
    font-size: 1rem;
    line-height: 1.7rem;
}
.wysiwyg img{
width: 100%;
  height: 100%;
  contain: cover;
}
.wysiwyg table {
  border-collapse: collapse; /* Ensures borders merge properly */
}

.wysiwyg #content-data table td, 
.wysiwyg #content-data table th {
  text-align: center !important;
  vertical-align: middle;
  border: 1px solid black;
}

@media screen and (max-width: 768px) {
    #content-data {
        padding: 0 4rem;
    }
}

@media screen and (max-width: 512px) {
    #content-data {
        padding: 0 2rem;
    }
}

@media screen and (max-width: 425px) {
    #content-data {
        padding: 0 1rem;
    }
}
p{
    text-align:justify;
}


.event-news-page {
    margin-top: var(--navbar-height);
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    width: 100%;
}
.event-news-page .header {
    background-color: whitesmoke;
    color: var(--heading-color);
    margin-top: 10%;
    place-self: center;
    font-size: 2.6rem;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--accent-color);
    background-color: #f5f5f5;
}

.event-news-page .content-list {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.news-events {
    grid-gap: 1rem;
    gap: 1rem;
    align-items: flex-start;
    padding: 1rem 1rem;
    border-radius: 5px;
    width: 100%;
    border: 2px solid var(--color-secondary);
    transition: all 0.3s ease-in-out;
    min-height: 200px;
    max-width: 800px;
}

/* .news-events:hover {
    transform: translateY(-5px);
} */

.news-events .date {
    color: grey;
    font-weight: bold;
}

.news-events .link {
    text-decoration: none;
    font-weight: bold;
    color: var(--accent-color);
}

.short-desc {
    width: 100%;
    color: rgba(111, 111, 111, 0.9);
}

#event-filter{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    margin-bottom: 20px;
}

@media (max-width: 576px) {
    .event-news-wrapper {
        padding: 0 20px;
    }
}

.contact-us-page {
    margin-top: var(--navbar-height);
    background-color: white;
    /* width: 100%;
    height: 100%; */
}

.map-view {
    height: 350px;
    width: 100%;
    overflow: hidden;
    background-color: white;
}


.info-form {
    margin: 2rem auto;
    padding: 0 20px;
    /* height: 50%; */
    width: 90%;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: space-between;
}


.form-wrapper {
    width: 47%;
}

.form-heading {
    color: var(--accent-color);
    font-weight: bold;
    font-size: 1.8rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}

.msg-form {
    width: 100%;
    flex-direction: column;
}


.msg-form-field {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.7);
    padding: 0.5rem 0;
    grid-gap: 5px;
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.msg-form-field input[type="text"]{
    border-radius: 5px;
    padding: 7px 10px;
    border: solid 2px rgba(0, 0, 0, 0.3);
    width: 100%;
    font-size: 16px;
}

.msg-form-field textarea{
    border-radius: 5px;
    padding: 7px 10px;
    border: solid 2px rgba(0, 0, 0, 0.3);
    width: 100%;
    font-size: 16px;
    resize: none;
}


.msg-form-field input[type="text"]:focus {
    outline: none;
    border: solid 2px var(--accent-color);
}

.msg-form-field input[type="button"] {
    width: 300px;
    border: none;
    height: 50px;
    outline: none;
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.3);
    color: white;
    background-color: var(--accent-color);
    cursor: pointer;
    font-size: 18px;

}

.msg-form-field input[type="button"]:hover {

    border: solid 2px black;
}



.details-wrapper {
    padding-top: 20px;
}

.contact-details {
    border-bottom: dashed 1px var(--accent-color);
    padding-bottom: 1rem;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
}

.contact-details .icn {
    display: inline;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--accent-color);
}

.contact-details{
    flex-wrap: wrap;
}

.details{
    width: calc(100% - 60px);
}

.details .descrip{
    font-weight: 300;
}



@media(max-width:700px) {
    .info-form {
        width: 100%;
        flex-direction: column;
    }

    .form-wrapper {
        width: 90%;
        margin: 10px auto 30px auto;
    }
    .details{
        width: calc(100% - 60px);
    }
    .contact-details .icn {
        width: 30px;
        height: 30px;
    }
}
.achivements-contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.achivements-contents .achievement__box {
    margin: auto;
    max-height: 450px;
    padding: 10px;
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 250px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

.achievement__box .img {
    height: 100%;
    width: 30%;
    display: flex;
    background-color: white;
}
.img img {
    width: 100%;
    object-fit: contain;
}
.achievement__box .achievement__details {
    width: 70%;
    padding: 10px 0;
    grid-gap: 10px;
    gap: 10px;
}

.achievement__details .description {
    overflow-y: scroll;
    text-overflow: ellipsis;
    color: gray;
    min-width: 50vw;
    max-width: 60vw;
}

.achievement__details .description::-webkit-scrollbar {
    display: none;
}

.achievement__details .header-btn {
    max-width: 150px;
}

.achievement-details-page {
    min-height: 100vh;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--navbar-height);
}

.achievement-details-page .details-pane {
    padding: 0 40px;
    margin-top: 1rem;
    color: #646464;
}

.details-pane .date {
    color: #646464;
}
.details-pane .title {
    margin: 1rem 0;
}

.details-pane .img {
    border: solid 2px;
    max-width: 500px;
    margin-bottom: 1rem;
}

.details-pane .img img {
    object-fit: contain;
    height: 400px;
}

@media (max-width: 576px) {
    .achivements-contents .achievement__box {
        flex-direction: column;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .achievement__box .img {
        width: 100%;
    }
    .achievement-details-page {
        margin-top: 0;
    }
}

.placement {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.for-division {
    background-color: white;
    width: 80%;
    place-self: center;
    border-radius: 12px;
    padding: 0 40px;
    display: flex;
}
.for-division .content {
    width: 70%;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}
.for-division .sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
    grid-gap: 0;
    gap: 0;
}
.for-division .sub-content {
    margin-top: 3vh;
}
.for-division .officer-mail {
    display: flex;
}
.for-division .user-testimonials {
    margin-top: 2.5rem;
}
.for-division .carousel-img {
    height: 10%;
    object-fit: contain;
    width: 100%;
}
.for-division .carousel-img img {
    object-fit: contain;
    height: 100px;
}
.for-division .carousel-item {
    padding: 1rem;
    height: 300px;
    margin: 20px;
    width: 100%;
    border-radius: 15px;
    display: grid;
    place-items: center;
}
.for-division .carousel-right::-webkit-scrollbar {
    display: none;
}
.for-division .carousel-right {
    height: 100px;
    overflow: scroll;
}
.for-division .heading {
    place-self: flex-end;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: var(--heading-color);
    display: flex;
    justify-content: center;
    width: 100%;
}

.for-division .heading h1 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-size: 2.6rem;
}
.for-division .sub-heading {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.for-division .sub-heading::after {
    margin-top: 5px;
    content: "";
    height: 5px;
    width: 60px;
    background-color: #2c3e50;
}

.for-division .tnp-officer {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px 0px;
    border-radius: 3px;
    margin-bottom: 1rem;
}
.for-division .officer-info {
    width: 90%;
    margin-left: 5%;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}
.for-division .officer-mail {
    text-decoration: none;
    width: 90%;
    margin: 10px 0;
    margin-left: 5%;
}

.officer-mail a {
    text-decoration: none;
    color: var(--accent-color);
}

.head-strip {
    display: flex;
    align-items: center;

    height: 5vh;
    width: 20vw;
    border: 1px solid var(--accent-color);
    background-color: white;
    padding: 5px;
    border-radius: 3px;
}
.head-strip p {
    margin-left: 2vw;
}
.strip {
    height: 5vh;
    width: 20vw;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: var(--color-primary);
    border-radius: 3px;
    padding: 10px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    transition: all 0.4s;
}
.strip:hover {
    cursor: pointer;
    transform: translateY(-3px);
    background-color: var(--accent-color);
}

.strip:hover a,
.strip:hover i {
    color: white;
}

.sidebar a,
.strip i {
    transition: all 0.4s;
    color: white;
    text-decoration: none;
}
@media screen and (max-width: 1400px) {
    .for-division {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .for-division {
        display: block;
        width: 100%;
        padding: 20px;
    }
    .head-strip {
        width: 80vw;
        height: 6vh;
    }
    .strip {
        width: 80vw;
        height: 6vh;
    }
    .for-division .content {
        width: 100%;
    }
    .for-division .sidebar {
        width: 100%;
        margin-top: 5vh;
    }
}

.heading-title {
    display: flex;
    flex-direction: column;
}

.heading-title::after {
    margin-top: 20px;
    /* position: absolute; */
    content: "";
    height: 5px;
    width: 60px;
    background-color: #000;
}

.placement-news .news {
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 15px;
}
.placement-news .news:first-of-type {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.placement-news .news .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    max-width: 600px;
}
.placement-news .news-date {
    min-width: 20%;
}

.notice-title-discription{
    margin-left: 20px;
}

.notice-title-discription .title{
    font-size: 18px;
    font-weight: 550;
    color: #000;
}

.notice-title-discription .discription{
    font-size: 14px;
    font-weight: 400;
    color: #000;
}
.placement .years {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.placement .year {
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.4s;
    min-width: 10vw;
}
.placement .year.active {
    color: white;
    background-color: var(--accent-color);
    transition: all 0.4s;
}
.card-icon {
    display: flex;
    width: 20%;
}
.card-icon i {
    font-size: 3rem;
    margin-left: 2vw;
    margin-top: 3vh;
    color: rgba(0, 0, 0, 0.6);
}
.placement .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
}
.placement .data-card {
    border: solid 1px var(--accent-color);
    background-color: white;
    border-radius: 5px;
    height: 100px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    transition: all 0.4s;
}

.placement .data-card:hover {
    transform: translateY(-5px);
}
.stats {
    width: 100%;
    height: auto;
    border-collapse: collapse;

    text-align: center;
    min-height: 35vh;
}

.stats,
.stats head,
.stats th,
.stats tr,
.stats td {
    border: solid 2px #053742;
}
.stats thead th {
    border: solid 2px #053742;
}

.stats thead {
    height: 50px;
    color: white;
    background-color: #053742;
}
.stats tfoot {
    background-color: white;
}

.card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-info strong {
    font-size: 2rem;
}
@media screen and (max-width: 800px) {
    .summary .cards {
        grid-template-columns: auto;
    }
}

@media screen and (max-width: 576px) {
    .statistics-table {
        max-width: 576px;
        overflow: scroll;
    }    
}

.placement .branches {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.placement .branch {
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-top: 2vh;
    border-radius: 12px;
    padding: 10px;
    margin-right: 7px;
    cursor: pointer;
    transition: all 0.4s;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.placement .branch.active {
    color: white;
    background-color: var(--accent-color);
    transition: all 0.4s;
}

.placed-students .profile-cards {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 25px;
    gap: 25px;
    width: 100%;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    place-items: center;
}

.profile-card-grid{
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    min-height: 400px;
    place-items: center;
    
}

.profile-cards .profile-card {
    background-color: white;
    border-radius: 5px;
    max-width: 300px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    width: 250px;
    height: 400px;
}
.profile-cards .profile-card:hover {
    transform: translateY(-3px);
    transition: all 0.3s;
    box-shadow: var(--box-shadow);
}
.profile-card .student-image {
    min-height: 30%;
    display: grid;
    place-items: center;
    margin-bottom: 10px;
}
.student-image img {
    width: 60%;
    border-style: none;
    border-radius: 50%;
}
.profile-card .student-info {
    min-height: 50%;
    text-align: center;
    display: grid;
    place-items: center;
}
.student-info .comapany-image {
    min-height: 35%;
}
.student-info .comapany-image img {
    height: 100%;
    width: 100%;
    border-style: none;
}
.student-info .ctc {
    color: rgba(0, 0, 0, 0.6);
}
.student-info .paisa {
    width: 20vh;
    border: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    color: var(--accent-color);
}

@media screen and (max-width: 800px) {
    .placed-students .profile-cards {
        grid-template-columns: auto;
    }

    .placement .branch {
        min-width: 100%;
    }

    .content .cards {
        grid-template-columns: auto;
    }
}
h2{
font-size:30px;
}
.recruiters .companies {
    display: grid;
    width: 100%;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;
    gap: 5px;
    grid-row-gap:20px;
    row-gap:20px;
    margin-top: 3vh;
}
.companies .company {
    max-width: 250px;
    height: 400px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
}
.companies .company:hover {
    transform: translateY(-3px);
    box-shadow: var(--box-shadow);
    transition: all 0.3s;
}

.companies .company img {
    height: 70%;
    width: 100%;
    object-fit: contain;
    border-style: none;
}

.companies .company a{
    text-decoration: none;
    text-align: center;
    color: (--accent-color);
}
@media screen and (max-width: 800px) {
    .recruiters .companies {
        grid-template-columns: auto auto;
    }
}

.all-faculties-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    grid-gap: 5rem;
    gap: 5rem;
    width: 80%;
}

.departments {
    padding: 0 60px;
    background: none;
    width: 100%;
    flex-wrap: wrap;
}




.departments .department-name {
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-top: 2vh;
    border-radius: 10px;
    padding: 12px;
    margin-right: 1vw;
    cursor: pointer;
    transition: all 0.4s;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.departments .department-name.active {
    color: white;
    background-color: var(--accent-color);
    transition: all 0.4s;
}




/* .departments .department-name {
    color: #92967d;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all ease-in 0.1s;
}

.departments .department-name:hover {
    color: #505050;
}
.departments .active {
    color: #505050;
    font-weight: 800;
    transform: scale(1.1);
    transition: all ease-in 0.1s;
} */

.faculties {
    grid-gap: 2rem;
    gap: 2rem;
    flex-wrap: wrap;
}

.faculty {
    padding: 1rem;
    background: white;
    width: 350px;
    height: 550px;
    transition: all 0.3s;
    border-radius: 10px;
}

.faculty a {
    text-decoration: none;
}

.faculty:hover {
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.245);
}

.image {
    width: 100%;
    display: grid;
    place-items: center;
    height: 50%;
}

.image img {
    object-fit: cover;
    width: 50%;
    border-radius: 10px;
}

.faculty .data {
    color: #505050;
    height: 50%;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    text-align: center;
}

.faculty .data .name {
    color: var(--accent-color);
    font-size: 1.6rem;
    font-weight: bold;
}

.faculty .intreset {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 5.6em;
    line-height: 1.8em;
}

@media screen and (max-width: 1400px) {
    .faculties {
        width: 90%;
    }
}

@media screen and (max-width: 800px) {
    .all-faculties-page {
        width: 95%;
    }
}

@media (max-width: 576px) {
    .all-faculties-page {
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%;
    }

    .faculty {
        margin: 0;
        width: 300px;
        height: 450px;
        /* margin-bottom: 2vh; */
    }

    .departments {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
    }
    .departments h3 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    .heading {
        flex-wrap: wrap;
    }

    .intreset {
        font-size: 12px;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 5.6em;
        line-height: 1.8em;
    }

    .departments .department-name{
        width: 100%;
    }
}

.all-faculties-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    grid-gap: 5rem;
    gap: 5rem;
    width: 80%;
}

.departments {
    padding: 0 60px;
    background: none;
    width: 100%;
    flex-wrap: wrap;
}




.departments .department-name {
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-top: 2vh;
    border-radius: 10px;
    padding: 12px;
    margin-right: 1vw;
    cursor: pointer;
    transition: all 0.4s;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.departments .department-name.active {
    color: white;
    background-color: var(--accent-color);
    transition: all 0.4s;
}




/* .departments .department-name {
    color: #92967d;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all ease-in 0.1s;
}

.departments .department-name:hover {
    color: #505050;
}
.departments .active {
    color: #505050;
    font-weight: 800;
    transform: scale(1.1);
    transition: all ease-in 0.1s;
} */

.faculties {
    grid-gap: 2rem;
    gap: 2rem;
    flex-wrap: wrap;
}

.faculty {
    padding: 1rem;
    background: white;
    width: 350px;
    height: 450px;
    transition: all 0.3s;
    border-radius: 10px;
}

.faculty a {
    text-decoration: none;
}

.faculty:hover {
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.245);
}

.image {
    width: 100%;
    display: grid;
    place-items: center;
    height: 50%;
}

.image img {
    object-fit: cover;
    width: 50%;
    border-radius: 10px;
}

.faculty .data {
    color: #505050;
    height: 50%;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    text-align: center;
}

.faculty .data .name {
    color: var(--accent-color);
    font-size: 1.6rem;
    font-weight: bold;
}

p .name{
  text-align:center;
}
.faculty .intreset {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 5.6em;
    line-height: 1.8em;
}

@media screen and (max-width: 1400px) {
    .faculties {
        width: 90%;
    }
}

@media screen and (max-width: 800px) {
    .all-faculties-page {
        width: 95%;
    }
}

@media (max-width: 576px) {
    .all-faculties-page {
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%;
    }

    .faculty {
        margin: 0;
        width: 300px;
        height: 350px;
        /* margin-bottom: 2vh; */
    }

    .departments {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
    }
    .departments h3 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    .heading {
        flex-wrap: wrap;
    }

    .intreset {
        font-size: 12px;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 5.6em;
        line-height: 1.8em;
    }

    .departments .department-name{
        width: 100%;
    }
}

.faculty-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 0 40px;
}

.faculty-name {
    margin-top: 3rem;
    font-size: 3rem;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
}

.faculty-profile .head2 {
    font-size: 2rem;
    font-weight: 400;
    text-decoration: underline;
}

.faculty-profile .buttons {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.faculty-profile .buttons p {
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-top: 2vh;
    border-radius: 12px;
    padding: 10px;
    margin-right: 1vw;
    cursor: pointer;
    transition: all 0.4s;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.faculty-profile .buttons p:hover {
    color: white;
    background-color: var(--accent-color);
    transition: all 0.4s;
}

.details-section {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
}

.personal-details .image {
    border-radius: 10%;
    border: solid 1px rgba(0, 0, 0, 0.3);
    width: 50%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.personal-details .image img {
     border-radius: 20%;
    object-fit: contain;

    width: 300px;
    height: 300px;
}

.personal-details .name {
    font-size: 1.8rem;
    font-weight: bold;
    padding-bottom: 2rem;
    color: var(--color-secondary);
}

.basic-info .info-table {
    border: red;
    border-collapse: collapse;
}

.basic-info .info-table td {
    border: none;
}

.basic-info .info-table .field {
    color: #656666;
    font-weight: 600;
}

.basic-info .info-table .value {
    font-size: 1.2rem;
    color: #363636;
}

.information {
    grid-gap: 2rem;
    gap: 2rem;
    width: 100%;
}

.t_w .information {
    max-width: 100%;
}

.data-table {
    overflow-x: auto;
    border-collapse: collapse;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.2);
}
.data-table tr {
    height: 60px;
}

.data-table th {
    font-size: 1.2rem;
    font-weight: bold;
}

.data-table th,
td {
    border: solid 1px #555555;
    padding: 0.5rem;
}

.information ul {
    list-style-type: circle;
}

.info-list-item {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 1.2rem;
    color: #383838;
}
.info-list-item:before {
    content: "➜";
    color: var(--color-secondary);
    font-size: 1.5rem;
    padding-right: 0.5rem;
}

.numbered-list-item {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 1.2rem;
    color: #383838;
    margin-bottom: 0.8rem; 
}

.numbered-list-item:before {
    counter-increment: list-counter;
    content: counter(list-counter) ". ";
    color: var(--color-secondary); 
    font-size: 1.5rem;
    font-weight: bold;
    padding-right: 0.5rem;
}

.numbered-list {
    counter-reset: list-counter;
}

.studyInformation{
    padding: 20px;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    flex-wrap: wrap;
}


.studyContainer{
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    min-width: calc(50% - 10px);
    padding: 20px;
}

.studyContainer h5{
    margin-bottom: 5px;
}

.studyContainer a{
    color: var(--color-secondary);
    text-decoration: none;
}

.personal-details .info-table td {
    text-align: left;
}


@media screen and (max-width: 768px) {
    .faculty-profile {
        padding: 0;
    }

    .personal-details {
        flex-direction: column;
    }
    .information {
        flex-direction: column;
        align-items: center;
        flex-direction: column-reverse;
    }
    .information .image {
        width: 100%;
    }

    .table-wrapper {
        overflow: scroll;
        max-width: 600px;
    }
    .studyInformation{
        flex-direction: column;
    }
    
}

@media screen and (max-width: 576px) {
    .details-section {
        padding: 10px;
    }
    .personal-details .name {
     text-align: center;
    }
    .faculty-profile {
        padding: 0;
    }
    .table-wrapper {
        max-width: 350px;
    }

    .info-table-row {
        display: flex;
        flex-direction: column;
    }

    .faculty-profile .buttons {
        padding: 20px;
    }

    .faculty-profile .buttons p {
        width: 100%;
    }
    
}
@media screen and (max-width: 320px) {
    .details-section {
        padding: 5px;
    }
    .table-wrapper {
        max-width: 250px;
    }
    .info-table-row {
        max-width: 280px;
    }
}

.home-news {
    z-index: 11;
    position: fixed;
    right: -400px;
    top: 50vh;
}

.show-news {
    transition: linear 0.4s;
    transform: translateX(-400px) translateY(-50%);
}

.hide-news {
    transition: linear 0.4s;
    transform: translateX(0) translateY(-50%);
}

.toggel-btn {
    transition: ease 0.3s;
}

.toggel-btn:hover {
    cursor: pointer;
    transition: ease 0.3s;
    transform: scale(1.2);
}

.btn-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 20px 2px;
    border-radius: 0 1rem 1rem 0;
    font-size: 1.4rem;
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    color: white;
    border: solid 2px white;
    border-left: none;
    background-color: var(--color-secondary);
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}

.news-section {
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    padding: 0 20px;
    height: 400px;
    width: 400px;
    background-color: #fff;
}

.news-section .header {
    height: 10%;
    border-bottom: solid 2px var(--accent-color);
    justify-content: space-between;
}

.news-section .header i {
    cursor: pointer;
    font-size: 1.5rem;
}
.news-data .to-all{
    text-align: center;
    margin: 5px;
    padding: 5px;
}
.news-section .news-data {
    margin-top: 10px;
    height: 350px;
    overflow: scroll;
}

.news-section .news-data::-webkit-scrollbar {
    display: none;
}

.news-row {
    list-style-type: none;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    /* border-bottom: dashed 2px var(--accent-color); */
}

.news-row:nth-child(odd) {
    background-color: var(--color-secondary-light);
}

.news-row a {
    color: rgba(0, 0, 0);
    text-decoration: none;
}

.news-row a:hover {
    color: var(--accent-color);
}

.news-data-wrapper .data {
    font-size: 1rem;
}

.news-data-wrapper .date-time {
    font-size: 0.7rem;
}

@media (max-width: 576px) {
    .home-news {
        display: none;
    }
}

.home-notice {
    z-index: 11;
    position: fixed;
    left: -400px;
    top: 50vh;
}

.show-notice {
    transition: linear 0.4s;
    transform: translateX(400px) translateY(-50%);
}

.hide-notice {
    transition: linear 0.4s;
    transform: translateX(0) translateY(-50%);
}

.btn-wrapper2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 20px 2px;
    border-radius: 1rem 0 0 1rem;
    font-size: 1.4rem;
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    color: white;
    border: solid 2px white;
    border-right: none;
    background-color: var(--color-secondary);
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

.news .data-row{
    text-decoration: none;
    color: black;
}

.news .data-row:hover{
    color: var(--color-secondary);
}

@media (max-width: 576px) {
    .home-notice {
        display: none;
    }
}

.about-college {
    min-height: 100vh;
    padding: 5rem 6rem;
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.about-college-title {
    display: flex;
    flex-direction: column;
}

.about-college-title::after {
    margin-top: 20px;
    /* position: absolute; */
    content: "";
    height: 5px;
    width: 60px;
    background-color: #000;
}

.about-college-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.about-content-wrapper {
    display: flex;
}

.about-content {
    word-spacing: 2px;
    max-width: 60%;
    text-align: justify;
    color: #666666;
    /* max-height: 200px; */
    display: flex;
    flex-direction: column;
    place-self: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.about-content .header-btn {
    cursor: pointer;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.about-content:nth-child(2) {
    max-width: 60%;
}

.about-img-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.about-img-wrapper.pc span {
    text-align: center;
}

.about-img-wrapper.pc img {
    height: 200px;
    margin-bottom: 1rem;
    border-radius: 10px;
    border: solid 2px var(--color-primary);
}

.vision-mission {
    position: relative;
    display: flex;
    width: 100%;
    height: 300px;
    /* background-attachment: fixed; */
    overflow: hidden;
}
.vision-mission .parallex-content {
    width: 100%;
    background-color: rgb(88 88 88 / 60%);
    z-index: 2;
}

.content-vision {
    color: white;
}

.parallex {
    position: absolute;
    left: 0;
    top: -0%;
    width: 100%;
    height: 300px;
}

.parallex img {
    width: 100%;
    top: -100%;
    position: absolute;
    height: 250%;
    object-fit: cover;
}

.see-more-text {
    position: relative;
    padding-bottom: 20px;
}

.see-more-text::after {
    /* z-index: -1; */
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 20px;
    width: 100%;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255)
    );
}

@media screen and (max-width: 768px) {
    .about-college {
        padding: 5rem 1.2rem;
    }

    .about-college-title {
        align-items: center;
        text-align: center;
    }

    .about-content-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .about-content,
    .about-content:nth-child(2) {
        max-width: 85%;
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        align-items: center;
        margin: 0rem;
    }
}

@media screen and (max-width: 576px) {
    .about-college-content {
        grid-gap: 2rem;
        gap: 2rem;
    }
    .about-content,
    .about-content:nth-child(2) {
        max-width: 100%;
        margin-right: 0;
    }

    .about-img-wrapper.gec {
        margin-top: 2rem;
    }
}

.clg-info {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-primary);
}

.carousel-container {
    overflow: hidden;
    display: flex;
    height: 70%;
    align-items: center;
    justify-content: center;
    max-height: 700px;
    width: 75%;
}

.clg-info-bar {
    height: 250px;
    width: 100%;
    display: flex;
}

.clg-info-bar-content {
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    flex: 1 1;
}

.info-content-icon i {
    color: white;
    font-size: 3.5rem;
}

.info-content-title h3 {
    font-weight: 500;
    font-size: 2rem;
    color: white;
}

.info-content-text {
    max-width: 250px;
    color: white;
    text-align: center;
}

.clg-info-bar-content:nth-child(odd) {
    background-color: var(--color-secondary);
}

.clg-info-bar-content:nth-child(even) {
    background-color: var(--color-primary);
}

.las{
    transition: 0.1s linear all;
}

.clg-info-bar-content:hover .las{
    font-size: 80px;
}

@media screen and (max-width: 768px) {
    .clg-info-bar {
        flex-basis: 50%;
        flex-wrap: wrap;
    }

    .clg-info-bar-content {
        height: 300px;
        flex: none;
        width: 50%;
    }

    .clg-info-bar-content:nth-child(3) {
        background-color: var(--color-primary);
    }
    .clg-info-bar-content:nth-child(4) {
        background-color: var(--color-secondary);
    }
}

@media screen and (max-width: 576px) {
    .carousel-container {
        height: 600px;
        width: 100%;
    }

    .gallery-img img {
        height: 600px;
        object-fit: cover;
    }

    .clg-info-bar {
        flex-basis: auto;
        height: 1000px;
        flex-direction: column;
    }

    .clg-info-bar-content {
        flex: 1 1;
        width: 100%;
        flex-direction: column;
    }

    .clg-info-bar-content:nth-child(3) {
        background-color: var(--color-secondary);
    }

    .clg-info-bar-content:nth-child(4) {
        background-color: var(--color-primary);
    }

    .info-content-title h3 {
        font-size: 1.5rem;
    }

    .info-content-text {
        max-width: 150px;
    }
}

.digital-info {
    min-height: 100vh;
    width: 100%;
    padding: 5rem 3rem;
    display: flex;
    grid-gap: 3rem;
    gap: 3rem;
    flex-direction: column;
    align-items: center;
}

.digital-info-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.digital-info-title::after {
    margin-top: 20px;
    /* position: absolute; */
    content: "";
    height: 5px;
    width: 60px;
    background-color: #000;
}

.info-container {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    height: 100%;
}

.news-events-container {
    width: 100%;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.info-box {
    padding: 1rem;
    width: 100%;
    min-height: 500px;
    border: solid 1px #000;
    max-width: 700px;
    overflow: scroll;
    grid-gap: 1rem;
    gap: 1rem;
    border-radius: 5px;
    max-height:600px;
}

.info-box::-webkit-scrollbar {
    display: none;
}

.notice-box {
    max-width: 100%;
    min-height: 500px;
    max-height: 500px;
}

.news-box,
.events-box {
    width: 50%;
}

.box-header {
    height: 40px;
    display: flex;
    justify-content: space-between;
}

.header-icon i {
    color: var(--color-secondary);
    font-size: 2rem;
    margin-right: 1rem;
}

.header-btn {
    color: #2d2e2e;
    text-decoration: none;
    padding: 0.7rem 1.4rem;
    border-radius: 5px;
    border: solid 2px var(--color-secondary);
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.header-btn:hover {
    color: white;
    background-color: var(--color-secondary);
    transform: scale(0.97);
}

.info-box-content {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.info-box-data {
    padding: 10px 20px;
    color: #474747;
    transition: all 0.3s ease-in-out;
}
.info-box-data a {
    transition: all 0.3s ease-in-out;
    color: #474747;
    text-decoration: none;
}

.info-box-data:hover,
.info-box-data:hover a {
    cursor: pointer;
    color: var(--color-secondary);
}

.info-box-data:nth-child(odd),
.info-box-data:nth-child(odd) a {
    transition: all 0.3s ease-in-out;
    color: white;
    border-radius: 5px;
    background-color: var(--color-secondary-light);
}

.info-box-data:nth-child(odd):hover,
.info-box-data:nth-child(odd):hover a {
    cursor: pointer;
    color: var(--color-primary);
}

@media screen and (max-width: 576px) {
    .digital-info {
        padding: 3rem 1rem;
        grid-gap: 2rem;
        gap: 2rem;
    }

    .news-events-container {
        flex-direction: column;
    }
    .info-box {
        max-width: 100%;
    }
    .news-box,
    .events-box {
        width: 100%;
    }

    .box-header {
        height: auto;

        flex-direction: column;
    }

    .header-icon {
        display: none;
    }
    .header-title {
        font-size: 1.8rem;
    }
}

.department-page {
    display: grid;
    grid-template-columns: 1fr 350px;
    min-height: 100vh;
    grid-row-gap: 6rem;
    row-gap: 6rem;
    max-width: 1640px;
}

.department-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
    padding: 0 4rem;
}

.department-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    grid-gap: 3rem;
    gap: 3rem;
}

.department-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.department-info-title::after {
    margin-top: 5px;
    content: "";
    height: 5px;
    width: 60px;
    background-color: #2c3e50;
}

.department-desc {
    /* max-width: 90%; */
    font-size: 1.1rem;
    text-align: justify;
}

.department-news-events {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    grid-gap: 3rem;
    gap: 3rem;
    grid-column: 1/-1;
    padding: 0 4rem;
}

.department-news-events #news,
.department-news-events #events {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.department-sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    grid-gap: 2rem;
    gap: 2rem;
    padding-right: 4rem;
}

.department-sidebar .strip,
.department-sidebar .head-strip {
    width: 100%;
}

.department-sidebar .head-strip{
    height: 90px;
}

.department-labs {
    grid-column: 1/-1;
    padding: 0 4rem;
}

.lab-card {
    /* border: 1px solid #2c3e50; */
    margin: 2rem 0;
    width: 100%;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.lab-info {
    padding: 2rem 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.lab-info .lab-title {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 3px solid #2c3e50;
}

.lab-info .lab-desc {
    overflow-y: scroll;
    max-height: 200px;
    text-overflow: ellipsis;
    max-width: 600px;
    text-align: justify;
}
.lab-desc::-webkit-scrollbar {
    display: none;
}

.lab-gallery {
    width: 400px;
    place-self: center;
}

.lab_image {
    width: 400px;
    height: 300px;
    border-radius: 10px;
}

.lab_image img {
    height: 100%;
    object-fit: contain;
}

.department-footer .copy-right {
    text-align: center;
    padding: 0 20px;
    background-color: var(--accent-color);
    height: 100px;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .sidebar {
        display: none;
    }
    .department-page {
        grid-template-columns: 1fr;
        grid-row-gap: 3rem;
        row-gap: 3rem;
    }

    .department-page-data,
    .department-news-events,
    .department-labs {
        padding: 0 2rem;
    }

    .lab-card {
        flex-direction: column;
    }
}

@media screen and (max-width: 536px) {
    .department-page-data,
    .department-news-events {
        padding: 0 2rem;
    }

    .department-labs {
        width: 100%;
        padding: 1rem;
        margin: auto;
    }
    .lab-gallery {
        max-width: 350px;
    }

    .department-labs-list {
        grid-gap: 2rem;
        gap: 2rem;
    }
    .lab-info {
        padding: 0;
    }

    .lab-info .lab-title {
        text-align: center;
    }

    .lab-card {
        align-items: center;
        max-width: 100%;
    }
    .lab_image {
        height: 300px;
        object-fit: contain;
    }
}

@media screen and (max-width: 425px) {
    .department-page-data,
    .department-news-events {
        padding: 0 13px;
    }

    .department-labs {
        padding: 1rem;
    }
    .department-labs-list {
        grid-gap: 2rem;
        gap: 2rem;
    }
    .lab-info {
        padding: 0;
    }

    .lab-info .lab-title {
        text-align: center;
    }

    .lab-card {
        align-items: center;
        max-width: 100%;
    }
}

.department_news_child{
    width: 100%;
}
.department_news_child .dep_news_child{
    width: 100%;
    border-bottom: dashed 1px rgba(111, 111, 111, 0.4);
}
.dep_news_child p {
    color: silver;
}
.gallery-page {
    padding: 0 40px;
    place-items: center;
    justify-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    gap: 2rem;
}

.gallery-page .categories {
    list-style: none;
    display: flex;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.categories .category {
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.gallery-page .gallery {
    display: grid;
    place-items: center; /* Keeps items centered */
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem; /* Keeps the larger gap */
}

/*
.gallery-page .gallery {
    display: grid;
    gap: 3rem;
    place-items: center;
    grid-template-columns: repeat(5, 1fr);
}*/

/* Ensure image remains centered and fully visible */
.image-carousel-wrapper .image-carousel img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain; /* Ensure the whole image fits without cropping */
    display: block;
    margin: auto;
}

/* Center carousel container properly */
.image-container .image-carousel-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

/* Ensure image stays centered */
.image-container .image-carousel-wrapper .img-gallery {
    height: 500px;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image-carousel-wrapper .image-carousel {
    position: relative;
    height: 100%;
    width: 100%;
}

.image-carousel .header-btn {
    border-color: white;
    position: absolute;
    top: 100px;
    right: 150px;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.gallery .image-container {
    cursor: pointer;
    width: 300px;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: solid 2px var(--accent-color);
}

.image-container:hover .image-description {
    opacity: 1;
}

.image-container .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
}

.image-wrapper .gallery-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.image-container .image-title {
    width: 100%;
    text-align: center;
}

.image-wrapper .image-description {
    text-align: center;
    font-size: 1.2rem;
    padding: 1rem;
    opacity: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    position: absolute;
    color: white;
    transition: all 0.3s linear;
}

/* Responsive Design */
@media (max-width: 576px) {
    .gallery-page {
        padding: 0 10px;
    }
    .gallery .image-container {
        max-width: 400px;
    }
    .gallery-page .gallery {
        grid-gap: 3rem;
        gap: 3rem;
        grid-template-columns: 1fr;
    }
    .image-container .image-carousel-wrapper .img-gallery {
        width: calc(100vw - 50px);
    }
    .image-carousel .header-btn {
        top: 30px;
        right: 30px;
    }
}
/*
.gallery-page .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
}*/

.gallery .image-container {
    overflow: hidden;
}

.image-container .image-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .gallery-page .gallery {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}

.hostel-page{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.notice-title-discription{
    margin-left: 20px;
}

.notice-title-discription .title{
    font-size: 18px;
    font-weight: 550;
    color: #000;
}

p{
    text-align:justify;
}

/*image slider*/
/* library_page.css */
.library-page {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
    padding: 0 4rem;
    max-width: 1640px;
    margin: auto;
}

.library-slider {
    width: 100%;
    max-width: 800px;
    margin: auto;
}

.slider_image {
    width: 100%;
    height: 500px;
    border-radius: 10px;
}

.slider_image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the area without distortion */
}

.library-content {
    text-align: justify;
    font-size: 1.1rem;
}




.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the .coe-page within its container */
    align-items: flex-start; /* Align the content to the left */
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}

.coe-lab-card {
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-lab-gallery {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    max-width: 200px; /* Adjust the max-width to keep the images small */
}

.coe-lab_image {
    width: 100%;
    height: 150px; /* Adjust the height as needed */
    overflow: hidden;
    border-radius: 10px;
}

.coe-lab_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.coe-lab-info {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    flex-grow: 1;
}

.coe-lab-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.coe-lab-desc {
    font-size: 1rem;
    text-align: justify;
}



.team .team_contain{
    display: flex;
    align-items: center;
    justify-content: center;
}

.team .team_contain .card_contain{
    grid-row-gap: 100px;
    row-gap: 100px;
    grid-column-gap: 30px;
    column-gap: 30px;
    /* background-color: aqua; */
    max-width: 85%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.team_contain .card_contain .card{
    background-color: white;
    width: 400px;
    height: 300px;
    /* border: 1px solid black; */
    border-radius: 30px;
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: "mname mpic"
    "mdesc mdesc"
    "msoc msoc";
    box-shadow: 0 .125rem .25rem rgba(42, 42, 42, 0.205)!important;
}

.card .member_img{
    transform: translateY(-50%);
    border-radius: 50%;
    /* background-color: aqua; */
    height: 130px;
    background: #f8f9fa!important;
    padding: 10px;
    box-shadow: 0 .125rem .25rem rgba(42, 42, 42, 0.205)!important;
    grid-area: mpic;
}
.card .member_name{
    font-size: 35px;
    padding-top: 20px;
    padding-left: 20px;
    /* background-color: blueviolet; */
    grid-area: mname;
}
.card .member_desc{
    /* background-color: cadetblue; */
    grid-area: mdesc;
    /* padding-bottom: 20px; */
    padding-left: 20px;
    color: gray;
}
.card .socials_contain{
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* background-color: chartreuse; */
    grid-area: msoc;
}
.card .socials_contain img{
    transition: .1s all linear;
    height: 30px;
}
.card .socials_contain img:hover{
    transform: translateY(-5px);
}

.teamTitle{
    margin: 90px 8% 90px 8%; 
    padding: 10px 0 10px 0;
    border-bottom: 2px solid var(--color-primary);    
    border-top: 2px solid var(--color-primary);
}
.first{
    margin: 0px 8% 90px 8%; 
}
.superset {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
}
.superset .toogleYear {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .superset {
        flex-direction: column;
        align-items: flex-start;
    }
}
.campus-map {
  text-align: center;
  padding: 20px;
}

.campusMapTitle {
  font-size: 2em;
  margin-bottom: 20px;
}

.campusMapImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.campusMapImage {
  max-width: 100%;
  height: auto;
}


.mandatory-disclosure {
  text-align: center;
  padding: 20px;
}

.mandatoryDisclosureTitle {
  font-size: 2em;
  margin-bottom: 20px;
}

.mandatoryDisclosureContent {
  font-size: 1.2em;
  line-height: 1.6;
  text-align: left;
  display: inline-block;
  max-width: 800px;
}

.mandatoryLinks {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  display: inline-block;
  margin-top: 20px;
}

.mandatoryLinks li {
  margin: 10px 0;
}

.mandatoryLinks a {
  text-decoration: none;
  color: #007bff;
}

.mandatoryLinks a:hover {
  text-decoration: underline;
}


.important-links {
  text-align: center;
  padding: 20px;
}

.importantLinksTitle {
  font-size: 2em;
  margin-bottom: 20px;
}

.importantLinks {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  display: inline-block;
}

.importantLinks li {
  margin: 10px 0;
}

.importantLinks a {
  text-decoration: none;
  color: #007bff;
}

.importantLinks a:hover {
  text-decoration: underline;
}


.admission-process {
  text-align: center;
  padding: 20px;
}

.admissionProcessTitle {
  font-size: 2em;
  margin-bottom: 20px;
}

.admissionProcessContent {
  font-size: 1.2em;
  line-height: 1.6;
  text-align: left;
  display: inline-block;
  max-width: 900px;
}

.admissionLinks {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  display: inline-block;
  margin-top: 20px;
}

.admissionLinks li {
  margin: 10px 0;
}

.admissionLinks a {
  text-decoration: none;
  color: #007bff;
}

.admissionLinks a:hover {
  text-decoration: underline;
}


.clubs {
}
.clubs .clubs_contain {
    width: 80%;
    grid-gap: 20px;
    gap: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.clubs .clubs_contain .club_card {
    transition: 0.1s linear all;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    width: 350px;
    height:510px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
}
.clubs_contain .club_card .club_icon {
    height: 100px;
}
.clubs_contain .club_card .socials_contain {
    width: 100%;
    /* background-color: aqua; */
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.clubs_contain .club_card .name {
    font-size: 30px;
    font-weight: 500;
}

.club_card .desc{
    text-align: center;
}

.club_card .socials_contain .sm_contain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.socials_contain .sm_contain img {
    height: 30px;
}
.socials_contain .sm_contain label {
    font-size: 10px;
}
.clubs .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.club_card:hover {
    transform: translateY(-10px);
}

.activity-container {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: center;
}

.activity-card {
    width: 100%;
    max-width: 700px;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s ease-in-out;
}

.activity-card:hover {
    transform: scale(1.02);
}

.card-content {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    border: 1px solid #2c3e50;
    border-radius: 10px;
    overflow: hidden;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.activity-gallery {
    width: 400px;
    place-self: center;
}

.activity-image {
    width: 400px;
    height: 300px;
    border-radius: 10px;
}

.activity-image img {
    height: 100%;
    object-fit: contain;
}

.activity-info {
    padding: 2rem 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.activity-info .activity-title {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 3px solid #2c3e50;
    font-size: 1.2rem;
    font-weight: bold;
}

.activity-info .activity-desc {
    overflow-y: auto;
    max-height: 200px;
    text-overflow: ellipsis;
    max-width: 600px;
    text-align: justify;
}

.activity-desc::-webkit-scrollbar {
    display: none;
}

.underConstruction{
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.underConstruction h2{
    font-size: 30px;
}

@media screen and (max-width:800px){
    .underConstruction{
        background-color: aqua;
    }
    .lottie_contain{
        height: 300px;
        width: 300px;
    }
}
.alumini-data-wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 10px;
    width: 100%;
    align-items: center;
}

.alumini-data-wrapper .alumini_item {
    min-width: 300px;
    max-width: 1300px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
}

@media screen and (max-width: 1400px) {
    .alumini-data-wrapper .alumini_item {
        width: 750px;
    }
}

@media screen and (max-width: 800px) {
    .alumini-data-wrapper .alumini_item {
        width: 400px;
    }
}


@media screen and (max-width: 576px) {
    .alumini-data-wrapper .alumini_item {
        width: 300px;
    }
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}

.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}

.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.coe-page {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}


.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    display: none;
    cursor: pointer;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: var(--color-secondary);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
}

.scroll-to-top div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll-to-top i {
    color: white;
}

