.page_404 {
    margin-top: var(--navbar-height);
    padding: 40px 0;
    height: 100vh;
    background: #fff;
}

.page_404 .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    text-align: center;
    width: 80%;
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    background-size: contain;
    background-repeat: no-repeat;
    height: 400px;
    background-position: center;
}

.four_zero_four_bg h1 {
    font-size: 80px;
    color: #646464;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
 
    padding: 10px 20px;
    margin: 20px 0;
    display: inline-block;
}
.contant_box_404 {
    text-align: center;
    color: #555;
}
