.info-page {
    background: var(--bg-primary);
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 50vh;
    padding: 0 40px;
}

.info-page .heading {
    color: #646464;
}

.info-page .title {
    font-size: 2.6rem;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--accent-color);
}

.info-page .entry-list {
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.entry-list li {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #646464;
    font-size: 1.2rem;
}

.entry-list li a:hover {
    color: var(--accent-color);
}

.entry-list .info_page_card {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;
    background: var(--bg-primary);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
