.department-page {
    display: grid;
    grid-template-columns: 1fr 350px;
    min-height: 100vh;
    row-gap: 6rem;
    max-width: 1640px;
}

.department-page-data {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 0 4rem;
}

.department-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 3rem;
}

.department-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.department-info-title::after {
    margin-top: 5px;
    content: "";
    height: 5px;
    width: 60px;
    background-color: #2c3e50;
}

.department-desc {
    /* max-width: 90%; */
    font-size: 1.1rem;
    text-align: justify;
}

.department-news-events {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 3rem;
    grid-column: 1/-1;
    padding: 0 4rem;
}

.department-news-events #news,
.department-news-events #events {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.department-sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 2rem;
    padding-right: 4rem;
}

.department-sidebar .strip,
.department-sidebar .head-strip {
    width: 100%;
}

.department-sidebar .head-strip{
    height: 90px;
}

.department-labs {
    grid-column: 1/-1;
    padding: 0 4rem;
}

.lab-card {
    /* border: 1px solid #2c3e50; */
    margin: 2rem 0;
    width: 100%;
    display: flex;
    gap: 1rem;
}

.lab-info {
    padding: 2rem 1rem;
    gap: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.lab-info .lab-title {
    width: fit-content;
    border-bottom: 3px solid #2c3e50;
}

.lab-info .lab-desc {
    overflow-y: scroll;
    max-height: 200px;
    text-overflow: ellipsis;
    max-width: 600px;
    text-align: justify;
}
.lab-desc::-webkit-scrollbar {
    display: none;
}

.lab-gallery {
    width: 400px;
    place-self: center;
}

.lab_image {
    width: 400px;
    height: 300px;
    border-radius: 10px;
}

.lab_image img {
    height: 100%;
    object-fit: contain;
}

.department-footer .copy-right {
    text-align: center;
    padding: 0 20px;
    background-color: var(--accent-color);
    height: 100px;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .sidebar {
        display: none;
    }
    .department-page {
        grid-template-columns: 1fr;
        row-gap: 3rem;
    }

    .department-page-data,
    .department-news-events,
    .department-labs {
        padding: 0 2rem;
    }

    .lab-card {
        flex-direction: column;
    }
}

@media screen and (max-width: 536px) {
    .department-page-data,
    .department-news-events {
        padding: 0 2rem;
    }

    .department-labs {
        width: 100%;
        padding: 1rem;
        margin: auto;
    }
    .lab-gallery {
        max-width: 350px;
    }

    .department-labs-list {
        gap: 2rem;
    }
    .lab-info {
        padding: 0;
    }

    .lab-info .lab-title {
        text-align: center;
    }

    .lab-card {
        align-items: center;
        max-width: 100%;
    }
    .lab_image {
        height: 300px;
        object-fit: contain;
    }
}

@media screen and (max-width: 425px) {
    .department-page-data,
    .department-news-events {
        padding: 0 13px;
    }

    .department-labs {
        padding: 1rem;
    }
    .department-labs-list {
        gap: 2rem;
    }
    .lab-info {
        padding: 0;
    }

    .lab-info .lab-title {
        text-align: center;
    }

    .lab-card {
        align-items: center;
        max-width: 100%;
    }
}
