.faculty-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 0 40px;
}

.faculty-name {
    margin-top: 3rem;
    font-size: 3rem;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
}

.faculty-profile .head2 {
    font-size: 2rem;
    font-weight: 400;
    text-decoration: underline;
}

.faculty-profile .buttons {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.faculty-profile .buttons p {
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-top: 2vh;
    border-radius: 12px;
    padding: 10px;
    margin-right: 1vw;
    cursor: pointer;
    transition: all 0.4s;
    min-width: fit-content;
}

.faculty-profile .buttons p:hover {
    color: white;
    background-color: var(--accent-color);
    transition: all 0.4s;
}

.details-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
}

.personal-details .image {
    border-radius: 10%;
    border: solid 1px rgba(0, 0, 0, 0.3);
    width: 50%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.personal-details .image img {
     border-radius: 20%;
    object-fit: contain;

    width: 300px;
    height: 300px;
}

.personal-details .name {
    font-size: 1.8rem;
    font-weight: bold;
    padding-bottom: 2rem;
    color: var(--color-secondary);
}

.basic-info .info-table {
    border: red;
    border-collapse: collapse;
}

.basic-info .info-table td {
    border: none;
}

.basic-info .info-table .field {
    color: #656666;
    font-weight: 600;
}

.basic-info .info-table .value {
    font-size: 1.2rem;
    color: #363636;
}

.information {
    gap: 2rem;
    width: 100%;
}

.t_w .information {
    max-width: 100%;
}

.data-table {
    overflow-x: auto;
    border-collapse: collapse;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.2);
}
.data-table tr {
    height: 60px;
}

.data-table th {
    font-size: 1.2rem;
    font-weight: bold;
}

.data-table th,
td {
    border: solid 1px #555555;
    padding: 0.5rem;
}

.information ul {
    list-style-type: circle;
}

.info-list-item {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 1.2rem;
    color: #383838;
}
.info-list-item:before {
    content: "➜";
    color: var(--color-secondary);
    font-size: 1.5rem;
    padding-right: 0.5rem;
}

.numbered-list-item {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 1.2rem;
    color: #383838;
    margin-bottom: 0.8rem; 
}

.numbered-list-item:before {
    counter-increment: list-counter;
    content: counter(list-counter) ". ";
    color: var(--color-secondary); 
    font-size: 1.5rem;
    font-weight: bold;
    padding-right: 0.5rem;
}

.numbered-list {
    counter-reset: list-counter;
}

.studyInformation{
    padding: 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}


.studyContainer{
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    min-width: calc(50% - 10px);
    padding: 20px;
}

.studyContainer h5{
    margin-bottom: 5px;
}

.studyContainer a{
    color: var(--color-secondary);
    text-decoration: none;
}

.personal-details .info-table td {
    text-align: left;
}


@media screen and (max-width: 768px) {
    .faculty-profile {
        padding: 0;
    }

    .personal-details {
        flex-direction: column;
    }
    .information {
        flex-direction: column;
        align-items: center;
        flex-direction: column-reverse;
    }
    .information .image {
        width: 100%;
    }

    .table-wrapper {
        overflow: scroll;
        max-width: 600px;
    }
    .studyInformation{
        flex-direction: column;
    }
    
}

@media screen and (max-width: 576px) {
    .details-section {
        padding: 10px;
    }
    .personal-details .name {
     text-align: center;
    }
    .faculty-profile {
        padding: 0;
    }
    .table-wrapper {
        max-width: 350px;
    }

    .info-table-row {
        display: flex;
        flex-direction: column;
    }

    .faculty-profile .buttons {
        padding: 20px;
    }

    .faculty-profile .buttons p {
        width: 100%;
    }
    
}
@media screen and (max-width: 320px) {
    .details-section {
        padding: 5px;
    }
    .table-wrapper {
        max-width: 250px;
    }
    .info-table-row {
        max-width: 280px;
    }
}
