.campus-map {
  text-align: center;
  padding: 20px;
}

.campusMapTitle {
  font-size: 2em;
  margin-bottom: 20px;
}

.campusMapImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.campusMapImage {
  max-width: 100%;
  height: auto;
}

