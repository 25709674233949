.hostel-page{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.notice-title-discription{
    margin-left: 20px;
}

.notice-title-discription .title{
    font-size: 18px;
    font-weight: 550;
    color: #000;
}

p{
    text-align:justify;
}

/*image slider*/
/* library_page.css */
.library-page {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 0 4rem;
    max-width: 1640px;
    margin: auto;
}

.library-slider {
    width: 100%;
    max-width: 800px;
    margin: auto;
}

.slider_image {
    width: 100%;
    height: 500px;
    border-radius: 10px;
}

.slider_image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the area without distortion */
}

.library-content {
    text-align: justify;
    font-size: 1.1rem;
}


