.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    display: none;
    cursor: pointer;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: var(--color-secondary);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
}

.scroll-to-top div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll-to-top i {
    color: white;
}
