.clg-info {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-primary);
}

.carousel-container {
    overflow: hidden;
    display: flex;
    height: 70%;
    align-items: center;
    justify-content: center;
    max-height: 700px;
    width: 75%;
}

.clg-info-bar {
    height: 250px;
    width: 100%;
    display: flex;
}

.clg-info-bar-content {
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex: 1;
}

.info-content-icon i {
    color: white;
    font-size: 3.5rem;
}

.info-content-title h3 {
    font-weight: 500;
    font-size: 2rem;
    color: white;
}

.info-content-text {
    max-width: 250px;
    color: white;
    text-align: center;
}

.clg-info-bar-content:nth-child(odd) {
    background-color: var(--color-secondary);
}

.clg-info-bar-content:nth-child(even) {
    background-color: var(--color-primary);
}

.las{
    transition: 0.1s linear all;
}

.clg-info-bar-content:hover .las{
    font-size: 80px;
}

@media screen and (max-width: 768px) {
    .clg-info-bar {
        flex-basis: 50%;
        flex-wrap: wrap;
    }

    .clg-info-bar-content {
        height: 300px;
        flex: none;
        width: 50%;
    }

    .clg-info-bar-content:nth-child(3) {
        background-color: var(--color-primary);
    }
    .clg-info-bar-content:nth-child(4) {
        background-color: var(--color-secondary);
    }
}

@media screen and (max-width: 576px) {
    .carousel-container {
        height: 600px;
        width: 100%;
    }

    .gallery-img img {
        height: 600px;
        object-fit: cover;
    }

    .clg-info-bar {
        flex-basis: auto;
        height: 1000px;
        flex-direction: column;
    }

    .clg-info-bar-content {
        flex: 1;
        width: 100%;
        flex-direction: column;
    }

    .clg-info-bar-content:nth-child(3) {
        background-color: var(--color-secondary);
    }

    .clg-info-bar-content:nth-child(4) {
        background-color: var(--color-primary);
    }

    .info-content-title h3 {
        font-size: 1.5rem;
    }

    .info-content-text {
        max-width: 150px;
    }
}
