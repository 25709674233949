.header {
    position: relative;
    
    
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    padding-top: 1rem;
    /* border-bottom: 10px solid var(--color-secondary); */
}

.header .head {
    display: flex;
}

.head__link {
    font-weight: 900;
    font-size: 1.5rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #0d0d0d;
}

.head__logo {
    flex: 3;
}

.head__img {
    height: 100px;
}

.head__contact {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.head__contact .contact__item {
    gap: 1rem;
    height: 100%;
    display: flex;
    width: 200px;
    align-items: center;
}

.contact-icon {
    font-size: 1.5rem;
    color: var(--color-secondary);
}

.contact__value {
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
}

.navbar {
    position: sticky;
    top: 0;
    max-width:100%;
    margin: 0 auto;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 2rem;

    background-color: var(--color-primary);
    height: 70px;

}

.navbar__list {
    
    height: 100%;
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    width: 100%;
}

.navbar__item {
    display: flex;
    height: 100%;
    position: relative;
    top: 0;
}

.navbar__item:hover .navbar__dropdown {
    display: block;
    visibility: visible;
    transform: translateY(0);
    z-index: 1;
    opacity: 1;
    background-color: var(--color-secondary);
}

.navbar__item .navbar__link {
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    text-decoration: none;
    display: flex;
    gap: 10px;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.navbar__link i {
    font-size: 1rem;
}

.navbar__link:hover {
    color: var(--color-secondary);
}

.navbar__dropdown {
    position: absolute;
    top: 70px;
    left: -20px;
    visibility: hidden;
    min-width: 300px;
    background-color: var(--color-primary);
    transition: all 0.1s linear;
    transform: translateY(-10%);
    z-index: -1;
    opacity: 0;
}

.navbar__item:nth-last-of-type(2) .navbar__dropdown {
    left: auto;
    right: -20px;
}

.navbar__dropdown-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem 2rem;
    width: 100%;
}

.navbar__dropdown-item a {
    text-decoration: none;
    color: #fff;
    transition: all 0.1s ease-in-out;
}

.navbar__dropdown-item:hover a {
    color: var(--color-primary);
}

.navbar-tab {
    display: none;
}

.contact__value:hover {
    text-decoration: underline;
    color: var(--color-secondary);
}

@media screen and (max-width: 768px) {
    .header {
        max-width: 100%;
    }
    .head {
        max-width: 90%;
        margin: 0 auto;
        flex-direction: column;
        margin-bottom: 1rem;
    }
    .head__emblem {
        display: none;
    }
    .navbar {
        display: none;
    }
    .navbar-tab {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--color-primary);
        align-items: flex-start;
        padding: 1rem 2rem;
        gap: 2rem;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    .navbar-toggler {
        display: flex;
        gap: 1rem;
        align-items: center;
        outline: none;
        border: none;
        background: transparent;
        color: rgba(255, 255, 255, 0.9);
        font-size: 1.2rem;
    }

    .navbar-tab__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        gap: 1rem;
    }

    .navbar-tab__item {
        list-style-type: none;
    }

    .navbar-tab__item a,
    .navbar-tab__item div {
        text-decoration: none;
        display: flex;
        gap: 10px;
        align-items: center;
        color: #fff;
    }

    .navbar-collapse {
        flex-basis: 100%;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        transition: max-height 1s linear, opacity 0.2s, visibility 0s;
        max-height: 900px;
        opacity: 1;
        visibility: visible;
    }
    .navbar-collapse:not(.expand) {
        transition: all 1s linear;
        max-height: 0;
        display: block;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
    }

    .navbar-tab__dropdown-collapse {
        transition: all 1s linear;
        max-height: 500px;
        opacity: 1;
        visibility: visible;
    }

    .navbar-tab__dropdown-collapse:not(.expand) {
        transition: all 1s linear;
        max-height: 0;
        display: block;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
    }
    .navbar-tab__dropdown-item {
        margin: 10px 0;
        margin-left: 2rem;
    }
}

header .name_title {
    font-size: 20px;
}

header .sub_tagline
{
    color: #797979;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    /* width: 400px; */
    /* background-color: aqua; */
}

@media screen and (max-width: 576px) {
    .head__logo a {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .head__contact {
        display: none;
        gap: 1rem;
        flex-direction: column;
    }
}
