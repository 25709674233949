.event-news-page {
    margin-top: var(--navbar-height);
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    width: 100%;
}
.event-news-page .header {
    background-color: whitesmoke;
    color: var(--heading-color);
    margin-top: 10%;
    place-self: center;
    font-size: 2.6rem;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--accent-color);
    background-color: #f5f5f5;
}

.event-news-page .content-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.news-events {
    gap: 1rem;
    align-items: flex-start;
    padding: 1rem 1rem;
    border-radius: 5px;
    width: 100%;
    border: 2px solid var(--color-secondary);
    transition: all 0.3s ease-in-out;
    min-height: 200px;
    max-width: 800px;
}

/* .news-events:hover {
    transform: translateY(-5px);
} */

.news-events .date {
    color: grey;
    font-weight: bold;
}

.news-events .link {
    text-decoration: none;
    font-weight: bold;
    color: var(--accent-color);
}

.short-desc {
    width: 100%;
    color: rgba(111, 111, 111, 0.9);
}

#event-filter{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    margin-bottom: 20px;
}

@media (max-width: 576px) {
    .event-news-wrapper {
        padding: 0 20px;
    }
}
