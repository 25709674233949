.gallery-page {
    padding: 0 40px;
    place-items: center;
    justify-items: center;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}

.gallery-page .categories {
    list-style: none;
    display: flex;
    width: 100%;
    gap: 1rem;
    flex-wrap: wrap;
}

.categories .category {
    cursor: pointer;
    width: fit-content;
}

.gallery-page .gallery {
    display: grid;
    place-items: center; /* Keeps items centered */
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem; /* Keeps the larger gap */
}

/*
.gallery-page .gallery {
    display: grid;
    gap: 3rem;
    place-items: center;
    grid-template-columns: repeat(5, 1fr);
}*/

/* Ensure image remains centered and fully visible */
.image-carousel-wrapper .image-carousel img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain; /* Ensure the whole image fits without cropping */
    display: block;
    margin: auto;
}

/* Center carousel container properly */
.image-container .image-carousel-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
}

/* Ensure image stays centered */
.image-container .image-carousel-wrapper .img-gallery {
    height: 500px;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image-carousel-wrapper .image-carousel {
    position: relative;
    height: 100%;
    width: 100%;
}

.image-carousel .header-btn {
    border-color: white;
    position: absolute;
    top: 100px;
    right: 150px;
    color: white;
    width: fit-content;
}

.gallery .image-container {
    cursor: pointer;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: solid 2px var(--accent-color);
}

.image-container:hover .image-description {
    opacity: 1;
}

.image-container .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    position: relative;
}

.image-wrapper .gallery-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.image-container .image-title {
    width: 100%;
    text-align: center;
}

.image-wrapper .image-description {
    text-align: center;
    font-size: 1.2rem;
    padding: 1rem;
    opacity: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    position: absolute;
    color: white;
    transition: all 0.3s linear;
}

/* Responsive Design */
@media (max-width: 576px) {
    .gallery-page {
        padding: 0 10px;
    }
    .gallery .image-container {
        max-width: 400px;
    }
    .gallery-page .gallery {
        gap: 3rem;
        grid-template-columns: 1fr;
    }
    .image-container .image-carousel-wrapper .img-gallery {
        width: calc(100vw - 50px);
    }
    .image-carousel .header-btn {
        top: 30px;
        right: 30px;
    }
}
/*
.gallery-page .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
}*/

.gallery .image-container {
    overflow: hidden;
}

.image-container .image-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .gallery-page .gallery {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}
