.home-news {
    z-index: 11;
    position: fixed;
    right: -400px;
    top: 50vh;
}

.show-news {
    transition: linear 0.4s;
    transform: translateX(-400px) translateY(-50%);
}

.hide-news {
    transition: linear 0.4s;
    transform: translateX(0) translateY(-50%);
}

.toggel-btn {
    transition: ease 0.3s;
}

.toggel-btn:hover {
    cursor: pointer;
    transition: ease 0.3s;
    transform: scale(1.2);
}

.btn-wrapper {
    width: fit-content;
    height: fit-content;
    padding: 20px 2px;
    border-radius: 0 1rem 1rem 0;
    font-size: 1.4rem;
    display: flex;
    gap: 0.5rem;
    color: white;
    border: solid 2px white;
    border-left: none;
    background-color: var(--color-secondary);
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}

.news-section {
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
    padding: 0 20px;
    height: 400px;
    width: 400px;
    background-color: #fff;
}

.news-section .header {
    height: 10%;
    border-bottom: solid 2px var(--accent-color);
    justify-content: space-between;
}

.news-section .header i {
    cursor: pointer;
    font-size: 1.5rem;
}
.news-data .to-all{
    text-align: center;
    margin: 5px;
    padding: 5px;
}
.news-section .news-data {
    margin-top: 10px;
    height: 350px;
    overflow: scroll;
}

.news-section .news-data::-webkit-scrollbar {
    display: none;
}

.news-row {
    list-style-type: none;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    /* border-bottom: dashed 2px var(--accent-color); */
}

.news-row:nth-child(odd) {
    background-color: var(--color-secondary-light);
}

.news-row a {
    color: rgba(0, 0, 0);
    text-decoration: none;
}

.news-row a:hover {
    color: var(--accent-color);
}

.news-data-wrapper .data {
    font-size: 1rem;
}

.news-data-wrapper .date-time {
    font-size: 0.7rem;
}

@media (max-width: 576px) {
    .home-news {
        display: none;
    }
}
