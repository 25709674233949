.placement-news .news {
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 15px;
}
.placement-news .news:first-of-type {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.placement-news .news .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    max-width: 600px;
}
.placement-news .news-date {
    min-width: 20%;
}

.notice-title-discription{
    margin-left: 20px;
}

.notice-title-discription .title{
    font-size: 18px;
    font-weight: 550;
    color: #000;
}

.notice-title-discription .discription{
    font-size: 14px;
    font-weight: 400;
    color: #000;
}