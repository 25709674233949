.footer-section {
    justify-content: space-between;
    margin-top: 3rem;
    width: 100%;
    background-color: #232323;
    color: #fff;
}

.footer-content {
    margin: 1rem;
    height: 100%;
}

.footer-logo {
    margin: 0 10px;
    width: 25%;
    height: 350px;
}

.footer-logo img {
    width: 8rem;
    border-radius: 50%;
}

.footer-logo .logo-text {
    padding: 10px;
    text-align: center;
}
.college_name{
    text-align: center;
}
.terms-condition {
    justify-content: space-between;
    padding: 0 20px;
    color: white;
    height: 40px;
    background-color: var(--accent-color);
}

.sections {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    padding: 20px 20px;
}

.link-section {
    width: 250px;
}

.link-section ul {
    list-style: none;
    gap: 10px;
}

.link-title {
    color: white;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 10px;
}

.footer-navItem a {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
    text-decoration: none;
}

.footer-navItem a:hover {
    color: var(--color-secondary);
}

/*.social-media .social-icon {
    margin: 10px;
    height: 30px;
    width: 30px;
}

.social-media .social-icon a {
    color: var(--color-secondary);
    font-size: 1.1rem;
}
/* .social-media-icons .social-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #2e2e2e;
} here

.social-icon a i {
    font-size: 2rem;
    color: #c6c6c6;
}

.social-icon:hover a i {
    color: var(--color-secondary);
}

/*
 *
 * */
	.social-media .social-icon {
    margin: 10px;
    height: 40px; /* Increase the height */
    width: 40px; /* Increase the width */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #2e2e2e; /* Dark background */
    transition: background-color 0.3s ease; /* Smooth background color transition */
}

.social-media .social-icon a {
    color: #c6c6c6; /* Initial icon color (gray) */
    font-size: 1.6rem; /* Increase font size */
    transition: color 0.3s ease; /* Smooth color transition */
}

.social-media .social-icon:hover {
    background-color: var(--color-secondary); /* Change background color on hover */
}

.social-media .social-icon:hover a {
    color: #fff; /* Change icon color on hover */
}

@media (max-width: 576px) {
    .footer-logo {
        width: 100%;
        height: fit-content;
        padding: 20px 0;
        margin: 0px;
        border: none;
        border-bottom: solid 1px var(--accent-color);
    }

    .footer-content {
        flex-direction: column;
    }

    .sections {
        align-items: center;
        flex-direction: column;
        border: none;
    }

    .link-section {
        width: 100%;
    }
    .terms-condition {
        justify-content: space-between;
        padding: 10px;
        color: white;
        height: fit-content;
        background-color: var(--accent-color);
    }
}

@media (max-width: 768px) {
}
