.placement .years {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    gap: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.placement .year {
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.4s;
    min-width: 10vw;
}
.placement .year.active {
    color: white;
    background-color: var(--accent-color);
    transition: all 0.4s;
}
.card-icon {
    display: flex;
    width: 20%;
}
.card-icon i {
    font-size: 3rem;
    margin-left: 2vw;
    margin-top: 3vh;
    color: rgba(0, 0, 0, 0.6);
}
.placement .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;
}
.placement .data-card {
    border: solid 1px var(--accent-color);
    background-color: white;
    border-radius: 5px;
    height: 100px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    transition: all 0.4s;
}

.placement .data-card:hover {
    transform: translateY(-5px);
}
.stats {
    width: 100%;
    height: auto;
    border-collapse: collapse;

    text-align: center;
    min-height: 35vh;
}

.stats,
.stats head,
.stats th,
.stats tr,
.stats td {
    border: solid 2px #053742;
}
.stats thead th {
    border: solid 2px #053742;
}

.stats thead {
    height: 50px;
    color: white;
    background-color: #053742;
}
.stats tfoot {
    background-color: white;
}

.card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-info strong {
    font-size: 2rem;
}
@media screen and (max-width: 800px) {
    .summary .cards {
        grid-template-columns: auto;
    }
}

@media screen and (max-width: 576px) {
    .statistics-table {
        max-width: 576px;
        overflow: scroll;
    }    
}
