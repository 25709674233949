.layout-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 6rem;
}

.layout-header {
    display: flex;
    height: 250px;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
}

.layout-header .layout-title {
    text-align: center;
    word-spacing: 0.5rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    color: white;
}

.breadcrumb-list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: var(--color-primary);
    gap: 1rem;
    list-style: none;
}

.breadcrumb-item {
    display: flex;
    height: 30px;
    gap: 1rem;
}
.breadcrumb-item i {
    /* color: var(--color-secondary); */
    color: white;
}

.breadcrumb-item a,
.breadcrumb-item span {
    color: var(--color-secondary);
    text-decoration: none;
}

.layout-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .layout-header {
        height: 150px;
        align-items: flex-start;
    }
    .layout-header .layout-title {
        text-align: center;
    }
}
