.mandatory-disclosure {
  text-align: center;
  padding: 20px;
}

.mandatoryDisclosureTitle {
  font-size: 2em;
  margin-bottom: 20px;
}

.mandatoryDisclosureContent {
  font-size: 1.2em;
  line-height: 1.6;
  text-align: left;
  display: inline-block;
  max-width: 800px;
}

.mandatoryLinks {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  display: inline-block;
  margin-top: 20px;
}

.mandatoryLinks li {
  margin: 10px 0;
}

.mandatoryLinks a {
  text-decoration: none;
  color: #007bff;
}

.mandatoryLinks a:hover {
  text-decoration: underline;
}

