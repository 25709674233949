.coe-page {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the page */
    align-items: flex-start;
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

/* Container for the ActivityCards section.
   The ActivityCard itself will retain its own styling from the clubs component. */
.coe-labs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
table {
  margin: auto; /* Centers the table */
  border-collapse: collapse; /* Ensures proper border rendering */
  width: 80%; /* Adjust width as needed */
}

th, td {
  text-align: center; /* Center aligns text in all cells */
  padding: 10px; /* Adds some spacing */
}

thead {
  background-color: #f2f2f2; /* Light background for header */
}

