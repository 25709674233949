.home-page {
   
}
.home-page .main-content {
    background-color: var(--bg-primary);
    margin-top: calc(var(--navbar-height));
}

.clg-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: calc(100vh - var(--navbar-height));
    background: url("../../assets/clg.jpg");
    background-size: cover;
}

.info {
    margin-top: var(--navbar-height);
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.all-content {
    /* padding: 20px; */
    background-color: var(--bg-primary);
}

@media (max-width: 576px) {
    .clg-bg {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        height: calc(100vh);
        background: url("../../assets/clg.jpg");
        background-size: cover;
        background-repeat: repeat;
    }
}
