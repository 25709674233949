.student-section {
    background: var(--bg-primary);
    padding-top: var(--navbar-height);
    display: flex;
    height: 100vh;
    flex-direction: column;
}
.heading {
    text-align: center;
}
.list {
    display: grid;
    place-items: center;
}
.item:hover {
    cursor: pointer;
    color: var(--accent-color);
}

@media (max-width: 810px) {
    .student-section {
        padding-top: 0;
    }
}
