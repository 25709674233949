.activity-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

.activity-card {
    width: 100%;
    max-width: 700px;
    display: flex;
    gap: 1rem;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s ease-in-out;
}

.activity-card:hover {
    transform: scale(1.02);
}

.card-content {
    display: flex;
    gap: 1rem;
    border: 1px solid #2c3e50;
    border-radius: 10px;
    overflow: hidden;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.activity-gallery {
    width: 400px;
    place-self: center;
}

.activity-image {
    width: 400px;
    height: 300px;
    border-radius: 10px;
}

.activity-image img {
    height: 100%;
    object-fit: contain;
}

.activity-info {
    padding: 2rem 1rem;
    gap: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.activity-info .activity-title {
    width: fit-content;
    border-bottom: 3px solid #2c3e50;
    font-size: 1.2rem;
    font-weight: bold;
}

.activity-info .activity-desc {
    overflow-y: auto;
    max-height: 200px;
    text-overflow: ellipsis;
    max-width: 600px;
    text-align: justify;
}

.activity-desc::-webkit-scrollbar {
    display: none;
}
