.heading-title {
    display: flex;
    flex-direction: column;
}

.heading-title::after {
    margin-top: 20px;
    /* position: absolute; */
    content: "";
    height: 5px;
    width: 60px;
    background-color: #000;
}
