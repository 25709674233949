.placement .branches {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.placement .branch {
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-top: 2vh;
    border-radius: 12px;
    padding: 10px;
    margin-right: 7px;
    cursor: pointer;
    transition: all 0.4s;
    min-width: fit-content;
}

.placement .branch.active {
    color: white;
    background-color: var(--accent-color);
    transition: all 0.4s;
}

.placed-students .profile-cards {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 25px;
    width: 100%;
    min-height: fit-content;
    place-items: center;
}

.profile-card-grid{
    display: grid;
    gap: 10px;
    width: 100%;
    min-height: 400px;
    place-items: center;
    
}

.profile-cards .profile-card {
    background-color: white;
    border-radius: 5px;
    max-width: 300px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    width: 250px;
    height: 400px;
}
.profile-cards .profile-card:hover {
    transform: translateY(-3px);
    transition: all 0.3s;
    box-shadow: var(--box-shadow);
}
.profile-card .student-image {
    min-height: 30%;
    display: grid;
    place-items: center;
    margin-bottom: 10px;
}
.student-image img {
    width: 60%;
    border-style: none;
    border-radius: 50%;
}
.profile-card .student-info {
    min-height: 50%;
    text-align: center;
    display: grid;
    place-items: center;
}
.student-info .comapany-image {
    min-height: 35%;
}
.student-info .comapany-image img {
    height: 100%;
    width: 100%;
    border-style: none;
}
.student-info .ctc {
    color: rgba(0, 0, 0, 0.6);
}
.student-info .paisa {
    width: 20vh;
    border: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    color: var(--accent-color);
}

@media screen and (max-width: 800px) {
    .placed-students .profile-cards {
        grid-template-columns: auto;
    }

    .placement .branch {
        min-width: 100%;
    }

    .content .cards {
        grid-template-columns: auto;
    }
}