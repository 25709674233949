.important-links {
  text-align: center;
  padding: 20px;
}

.importantLinksTitle {
  font-size: 2em;
  margin-bottom: 20px;
}

.importantLinks {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  display: inline-block;
}

.importantLinks li {
  margin: 10px 0;
}

.importantLinks a {
  text-decoration: none;
  color: #007bff;
}

.importantLinks a:hover {
  text-decoration: underline;
}

