.wysiwyg #content-data {
    padding: 0 6rem;
    display: flex;
    flex-direction: column;
    text-align:justify;
}
#content-data span,
#content-data div,
#content-data p {
    font-size: 1rem;
    line-height: 1.7rem;
}
.wysiwyg img{
width: 100%;
  height: 100%;
  contain: cover;
}
.wysiwyg table {
  border-collapse: collapse; /* Ensures borders merge properly */
}

.wysiwyg #content-data table td, 
.wysiwyg #content-data table th {
  text-align: center !important;
  vertical-align: middle;
  border: 1px solid black;
}

@media screen and (max-width: 768px) {
    #content-data {
        padding: 0 4rem;
    }
}

@media screen and (max-width: 512px) {
    #content-data {
        padding: 0 2rem;
    }
}

@media screen and (max-width: 425px) {
    #content-data {
        padding: 0 1rem;
    }
}
p{
    text-align:justify;
}

