.contact-us-page {
    margin-top: var(--navbar-height);
    background-color: white;
    /* width: 100%;
    height: 100%; */
}

.map-view {
    height: 350px;
    width: 100%;
    overflow: hidden;
    background-color: white;
}


.info-form {
    margin: 2rem auto;
    padding: 0 20px;
    /* height: 50%; */
    width: 90%;
    gap: 1rem;
    justify-content: space-between;
}


.form-wrapper {
    width: 47%;
}

.form-heading {
    color: var(--accent-color);
    font-weight: bold;
    font-size: 1.8rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}

.msg-form {
    width: 100%;
    flex-direction: column;
}


.msg-form-field {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.7);
    padding: 0.5rem 0;
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.msg-form-field input[type="text"]{
    border-radius: 5px;
    padding: 7px 10px;
    border: solid 2px rgba(0, 0, 0, 0.3);
    width: 100%;
    font-size: 16px;
}

.msg-form-field textarea{
    border-radius: 5px;
    padding: 7px 10px;
    border: solid 2px rgba(0, 0, 0, 0.3);
    width: 100%;
    font-size: 16px;
    resize: none;
}


.msg-form-field input[type="text"]:focus {
    outline: none;
    border: solid 2px var(--accent-color);
}

.msg-form-field input[type="button"] {
    width: 300px;
    border: none;
    height: 50px;
    outline: none;
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.3);
    color: white;
    background-color: var(--accent-color);
    cursor: pointer;
    font-size: 18px;

}

.msg-form-field input[type="button"]:hover {

    border: solid 2px black;
}



.details-wrapper {
    padding-top: 20px;
}

.contact-details {
    border-bottom: dashed 1px var(--accent-color);
    padding-bottom: 1rem;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.contact-details .icn {
    display: inline;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--accent-color);
}

.contact-details{
    flex-wrap: wrap;
}

.details{
    width: calc(100% - 60px);
}

.details .descrip{
    font-weight: 300;
}



@media(max-width:700px) {
    .info-form {
        width: 100%;
        flex-direction: column;
    }

    .form-wrapper {
        width: 90%;
        margin: 10px auto 30px auto;
    }
    .details{
        width: calc(100% - 60px);
    }
    .contact-details .icn {
        width: 30px;
        height: 30px;
    }
}