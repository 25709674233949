.all-faculties-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 5rem;
    width: 80%;
}

.departments {
    padding: 0 60px;
    background: none;
    width: 100%;
    flex-wrap: wrap;
}




.departments .department-name {
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-top: 2vh;
    border-radius: 10px;
    padding: 12px;
    margin-right: 1vw;
    cursor: pointer;
    transition: all 0.4s;
    min-width: fit-content;
}
.departments .department-name.active {
    color: white;
    background-color: var(--accent-color);
    transition: all 0.4s;
}




/* .departments .department-name {
    color: #92967d;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all ease-in 0.1s;
}

.departments .department-name:hover {
    color: #505050;
}
.departments .active {
    color: #505050;
    font-weight: 800;
    transform: scale(1.1);
    transition: all ease-in 0.1s;
} */

.faculties {
    gap: 2rem;
    flex-wrap: wrap;
}

.faculty {
    padding: 1rem;
    background: white;
    width: 350px;
    height: 450px;
    transition: all 0.3s;
    border-radius: 10px;
}

.faculty a {
    text-decoration: none;
}

.faculty:hover {
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.245);
}

.image {
    width: 100%;
    display: grid;
    place-items: center;
    height: 50%;
}

.image img {
    object-fit: cover;
    width: 50%;
    border-radius: 10px;
}

.faculty .data {
    color: #505050;
    height: 50%;
    justify-content: center;
    gap: 5px;
    text-align: center;
}

.faculty .data .name {
    color: var(--accent-color);
    font-size: 1.6rem;
    font-weight: bold;
}

p .name{
  text-align:center;
}
.faculty .intreset {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 5.6em;
    line-height: 1.8em;
}

@media screen and (max-width: 1400px) {
    .faculties {
        width: 90%;
    }
}

@media screen and (max-width: 800px) {
    .all-faculties-page {
        width: 95%;
    }
}

@media (max-width: 576px) {
    .all-faculties-page {
        gap: 1rem;
        width: 100%;
    }

    .faculty {
        margin: 0;
        width: 300px;
        height: 350px;
        /* margin-bottom: 2vh; */
    }

    .departments {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
    }
    .departments h3 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    .heading {
        flex-wrap: wrap;
    }

    .intreset {
        font-size: 12px;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 5.6em;
        line-height: 1.8em;
    }

    .departments .department-name{
        width: 100%;
    }
}
