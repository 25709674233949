.underConstruction{
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.underConstruction h2{
    font-size: 30px;
}

@media screen and (max-width:800px){
    .underConstruction{
        background-color: aqua;
    }
    .lottie_contain{
        height: 300px;
        width: 300px;
    }
}