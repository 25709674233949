.about-college {
    min-height: 100vh;
    padding: 5rem 6rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    height: fit-content;
}

.about-college-title {
    display: flex;
    flex-direction: column;
}

.about-college-title::after {
    margin-top: 20px;
    /* position: absolute; */
    content: "";
    height: 5px;
    width: 60px;
    background-color: #000;
}

.about-college-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.about-content-wrapper {
    display: flex;
}

.about-content {
    word-spacing: 2px;
    max-width: 60%;
    text-align: justify;
    color: #666666;
    /* max-height: 200px; */
    display: flex;
    flex-direction: column;
    place-self: center;
    gap: 2rem;
}

.about-content .header-btn {
    cursor: pointer;
    max-width: fit-content;
}

.about-content:nth-child(2) {
    max-width: 60%;
}

.about-img-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.about-img-wrapper.pc span {
    text-align: center;
}

.about-img-wrapper.pc img {
    height: 200px;
    margin-bottom: 1rem;
    border-radius: 10px;
    border: solid 2px var(--color-primary);
}

.vision-mission {
    position: relative;
    display: flex;
    width: 100%;
    height: 300px;
    /* background-attachment: fixed; */
    overflow: hidden;
}
.vision-mission .parallex-content {
    width: 100%;
    background-color: rgb(88 88 88 / 60%);
    z-index: 2;
}

.content-vision {
    color: white;
}

.parallex {
    position: absolute;
    left: 0;
    top: -0%;
    width: 100%;
    height: 300px;
}

.parallex img {
    width: 100%;
    top: -100%;
    position: absolute;
    height: 250%;
    object-fit: cover;
}

.see-more-text {
    position: relative;
    padding-bottom: 20px;
}

.see-more-text::after {
    /* z-index: -1; */
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 20px;
    width: 100%;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255)
    );
}

@media screen and (max-width: 768px) {
    .about-college {
        padding: 5rem 1.2rem;
    }

    .about-college-title {
        align-items: center;
        text-align: center;
    }

    .about-content-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .about-content,
    .about-content:nth-child(2) {
        max-width: 85%;
        max-height: fit-content;
        align-items: center;
        margin: 0rem;
    }
}

@media screen and (max-width: 576px) {
    .about-college-content {
        gap: 2rem;
    }
    .about-content,
    .about-content:nth-child(2) {
        max-width: 100%;
        margin-right: 0;
    }

    .about-img-wrapper.gec {
        margin-top: 2rem;
    }
}
