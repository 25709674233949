
.team .team_contain{
    display: flex;
    align-items: center;
    justify-content: center;
}

.team .team_contain .card_contain{
    row-gap: 100px;
    column-gap: 30px;
    /* background-color: aqua; */
    max-width: 85%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.team_contain .card_contain .card{
    background-color: white;
    width: 400px;
    height: 300px;
    /* border: 1px solid black; */
    border-radius: 30px;
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: "mname mpic"
    "mdesc mdesc"
    "msoc msoc";
    box-shadow: 0 .125rem .25rem rgba(42, 42, 42, 0.205)!important;
}

.card .member_img{
    transform: translateY(-50%);
    border-radius: 50%;
    /* background-color: aqua; */
    height: 130px;
    background: #f8f9fa!important;
    padding: 10px;
    box-shadow: 0 .125rem .25rem rgba(42, 42, 42, 0.205)!important;
    grid-area: mpic;
}
.card .member_name{
    font-size: 35px;
    padding-top: 20px;
    padding-left: 20px;
    /* background-color: blueviolet; */
    grid-area: mname;
}
.card .member_desc{
    /* background-color: cadetblue; */
    grid-area: mdesc;
    /* padding-bottom: 20px; */
    padding-left: 20px;
    color: gray;
}
.card .socials_contain{
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* background-color: chartreuse; */
    grid-area: msoc;
}
.card .socials_contain img{
    transition: .1s all linear;
    height: 30px;
}
.card .socials_contain img:hover{
    transform: translateY(-5px);
}

.teamTitle{
    margin: 90px 8% 90px 8%; 
    padding: 10px 0 10px 0;
    border-bottom: 2px solid var(--color-primary);    
    border-top: 2px solid var(--color-primary);
}
.first{
    margin: 0px 8% 90px 8%; 
}
.superset {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
}
.superset .toogleYear {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .superset {
        flex-direction: column;
        align-items: flex-start;
    }
}