@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&family=Poppins:wght@400;500;700;800;900&family=Zilla+Slab:wght@400;500;600;700&display=swap");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-padding-top: 60px;
    font-family: "Poppins",Verdana, Geneva, Tahoma, sans-serif;
    /* font-family: 'Poppins', sans-serif, 'Segoe UI', Tahoma, Geneva, Verdana; */
}
body {
    background-color: var(--bg-primary);
    /* max-width: 1800px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto; */
}
/* {
    --accent-color: #0d1128;
    --color-primary: #313552;
    --color-secondary: #B8405E;
} */
:root {
    --accent-color: #0d1128;
    --color-primary: #313552;
    --color-secondary: #e6a630;
    --color-secondary-light:#eeb741;
    --bg-primary: #fff;
    --heading-color: #646464;
    --navbar-height: 0px;
    --navbar-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    --y-pad: 0 30px;
    --box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.2);
}

.row {
    display: flex;
}

.column {
    display: flex;
    flex-direction: column;
}

.r-c-x {
    justify-content: center;
}

.r-c-y {
    align-items: center;
}

.c-c-x {
    justify-content: center;
}

.c-c-y {
    align-items: center;
}

.show {
    display: block;
}

.hide {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Zilla Slab", Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: #2d2e2e;
    line-height: 1.27273;
}

.title-bold {
    font-weight: 600;
}

h2,
.h2 {
    font-size: 44px;
}

h3,
.h3 {
    font-size: 38px;
}

h4,
.h4 {
    font-size: 32px;
}

h5,
.h5 {
    font-size: 26px;
}

h6,
.h6 {
    font-size: 18px;
}

@media screen and (max-width: 567px) {
    h1,
    .h1 {
        font-size: 32px;
    }

    h2,
    .h2 {
        font-size: 28px;
    }

    h3,
    .h3 {
        font-size: 24px;
    }

    h4,
    .h4 {
        font-size: 20px;
    }

    h5,
    .h5 {
        font-size: 18px;
    }
    h6,
    .h6 {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    h1,
    .h1 {
        font-size: 40px;
    }

    h2,
    .h2 {
        font-size: 36px;
    }

    h3,
    .h3 {
        font-size: 32px;
    }

    h4,
    .h4 {
        font-size: 28px;
    }

    h5,
    .h5 {
        font-size: 24px;
    }
    h6,
    .h6 {
        font-size: 20px;
    }
}
p{
    text-align:justify;
}
