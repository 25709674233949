.alumini-data-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 10px;
    width: 100%;
    align-items: center;
}

.alumini-data-wrapper .alumini_item {
    min-width: 300px;
    max-width: 1300px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
}

@media screen and (max-width: 1400px) {
    .alumini-data-wrapper .alumini_item {
        width: 750px;
    }
}

@media screen and (max-width: 800px) {
    .alumini-data-wrapper .alumini_item {
        width: 400px;
    }
}


@media screen and (max-width: 576px) {
    .alumini-data-wrapper .alumini_item {
        width: 300px;
    }
}

