h2{
font-size:30px;
}
.recruiters .companies {
    display: grid;
    width: 100%;
    grid-template-columns: auto auto auto;
    gap: 5px;
    row-gap:20px;
    margin-top: 3vh;
}
.companies .company {
    max-width: 250px;
    height: 400px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
}
.companies .company:hover {
    transform: translateY(-3px);
    box-shadow: var(--box-shadow);
    transition: all 0.3s;
}

.companies .company img {
    height: 70%;
    width: 100%;
    object-fit: contain;
    border-style: none;
}

.companies .company a{
    text-decoration: none;
    text-align: center;
    color: (--accent-color);
}
@media screen and (max-width: 800px) {
    .recruiters .companies {
        grid-template-columns: auto auto;
    }
}
