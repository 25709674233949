.clubs {
}
.clubs .clubs_contain {
    width: 80%;
    gap: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.clubs .clubs_contain .club_card {
    transition: 0.1s linear all;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    width: 350px;
    height:510px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
}
.clubs_contain .club_card .club_icon {
    height: 100px;
}
.clubs_contain .club_card .socials_contain {
    width: 100%;
    /* background-color: aqua; */
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.clubs_contain .club_card .name {
    font-size: 30px;
    font-weight: 500;
}

.club_card .desc{
    text-align: center;
}

.club_card .socials_contain .sm_contain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.socials_contain .sm_contain img {
    height: 30px;
}
.socials_contain .sm_contain label {
    font-size: 10px;
}
.clubs .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.club_card:hover {
    transform: translateY(-10px);
}
