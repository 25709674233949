.admission-process {
  text-align: center;
  padding: 20px;
}

.admissionProcessTitle {
  font-size: 2em;
  margin-bottom: 20px;
}

.admissionProcessContent {
  font-size: 1.2em;
  line-height: 1.6;
  text-align: left;
  display: inline-block;
  max-width: 900px;
}

.admissionLinks {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  display: inline-block;
  margin-top: 20px;
}

.admissionLinks li {
  margin: 10px 0;
}

.admissionLinks a {
  text-decoration: none;
  color: #007bff;
}

.admissionLinks a:hover {
  text-decoration: underline;
}

