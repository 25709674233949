.home-notice {
    z-index: 11;
    position: fixed;
    left: -400px;
    top: 50vh;
}

.show-notice {
    transition: linear 0.4s;
    transform: translateX(400px) translateY(-50%);
}

.hide-notice {
    transition: linear 0.4s;
    transform: translateX(0) translateY(-50%);
}

.btn-wrapper2 {
    width: fit-content;
    height: fit-content;
    padding: 20px 2px;
    border-radius: 1rem 0 0 1rem;
    font-size: 1.4rem;
    display: flex;
    gap: 0.5rem;
    color: white;
    border: solid 2px white;
    border-right: none;
    background-color: var(--color-secondary);
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

.news .data-row{
    text-decoration: none;
    color: black;
}

.news .data-row:hover{
    color: var(--color-secondary);
}

@media (max-width: 576px) {
    .home-notice {
        display: none;
    }
}
