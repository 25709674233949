.loader {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}


.content-loader{
    width: 90%;
    height: 400px;
    /* border: solid 2px #ccc; */

}