.digital-info {
    min-height: 100vh;
    width: 100%;
    padding: 5rem 3rem;
    display: flex;
    gap: 3rem;
    flex-direction: column;
    align-items: center;
}

.digital-info-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.digital-info-title::after {
    margin-top: 20px;
    /* position: absolute; */
    content: "";
    height: 5px;
    width: 60px;
    background-color: #000;
}

.info-container {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
}

.news-events-container {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.info-box {
    padding: 1rem;
    width: 100%;
    min-height: 500px;
    border: solid 1px #000;
    max-width: 700px;
    overflow: scroll;
    gap: 1rem;
    border-radius: 5px;
    max-height:600px;
}

.info-box::-webkit-scrollbar {
    display: none;
}

.notice-box {
    max-width: 100%;
    min-height: 500px;
    max-height: 500px;
}

.news-box,
.events-box {
    width: 50%;
}

.box-header {
    height: 40px;
    display: flex;
    justify-content: space-between;
}

.header-icon i {
    color: var(--color-secondary);
    font-size: 2rem;
    margin-right: 1rem;
}

.header-btn {
    color: #2d2e2e;
    text-decoration: none;
    padding: 0.7rem 1.4rem;
    border-radius: 5px;
    border: solid 2px var(--color-secondary);
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.header-btn:hover {
    color: white;
    background-color: var(--color-secondary);
    transform: scale(0.97);
}

.info-box-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.info-box-data {
    padding: 10px 20px;
    color: #474747;
    transition: all 0.3s ease-in-out;
}
.info-box-data a {
    transition: all 0.3s ease-in-out;
    color: #474747;
    text-decoration: none;
}

.info-box-data:hover,
.info-box-data:hover a {
    cursor: pointer;
    color: var(--color-secondary);
}

.info-box-data:nth-child(odd),
.info-box-data:nth-child(odd) a {
    transition: all 0.3s ease-in-out;
    color: white;
    border-radius: 5px;
    background-color: var(--color-secondary-light);
}

.info-box-data:nth-child(odd):hover,
.info-box-data:nth-child(odd):hover a {
    cursor: pointer;
    color: var(--color-primary);
}

@media screen and (max-width: 576px) {
    .digital-info {
        padding: 3rem 1rem;
        gap: 2rem;
    }

    .news-events-container {
        flex-direction: column;
    }
    .info-box {
        max-width: 100%;
    }
    .news-box,
    .events-box {
        width: 100%;
    }

    .box-header {
        height: auto;

        flex-direction: column;
    }

    .header-icon {
        display: none;
    }
    .header-title {
        font-size: 1.8rem;
    }
}
