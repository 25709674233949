.achivements-contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.achivements-contents .achievement__box {
    margin: auto;
    max-height: 450px;
    padding: 10px;
    width: 90%;
    height: fit-content;
    min-height: 250px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    gap: 20px;
}

.achievement__box .img {
    height: 100%;
    width: 30%;
    display: flex;
    background-color: white;
}
.img img {
    width: 100%;
    object-fit: contain;
}
.achievement__box .achievement__details {
    width: 70%;
    padding: 10px 0;
    gap: 10px;
}

.achievement__details .description {
    overflow-y: scroll;
    text-overflow: ellipsis;
    color: gray;
    min-width: 50vw;
    max-width: 60vw;
}

.achievement__details .description::-webkit-scrollbar {
    display: none;
}

.achievement__details .header-btn {
    max-width: 150px;
}

.achievement-details-page {
    min-height: 100vh;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--navbar-height);
}

.achievement-details-page .details-pane {
    padding: 0 40px;
    margin-top: 1rem;
    color: #646464;
}

.details-pane .date {
    color: #646464;
}
.details-pane .title {
    margin: 1rem 0;
}

.details-pane .img {
    border: solid 2px;
    max-width: 500px;
    margin-bottom: 1rem;
}

.details-pane .img img {
    object-fit: contain;
    height: 400px;
}

@media (max-width: 576px) {
    .achivements-contents .achievement__box {
        flex-direction: column;
        height: fit-content;
    }
    .achievement__box .img {
        width: 100%;
    }
    .achievement-details-page {
        margin-top: 0;
    }
}
