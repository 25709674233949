.coe-page {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    max-width: 1640px;
    margin: 0 auto; /* Center the .coe-page within its container */
    align-items: flex-start; /* Align the content to the left */
}

.coe-page-data {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.coe-about {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.coe-info-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.coe-desc {
    font-size: 1.1rem;
    text-align: justify;
}

.coe-labs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.coe-labs-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.coe-lab-card {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.coe-lab-gallery {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 200px; /* Adjust the max-width to keep the images small */
}

.coe-lab_image {
    width: 100%;
    height: 150px; /* Adjust the height as needed */
    overflow: hidden;
    border-radius: 10px;
}

.coe-lab_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.coe-lab-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-grow: 1;
}

.coe-lab-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.coe-lab-desc {
    font-size: 1rem;
    text-align: justify;
}

